//Imports for npm packages
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
//Imports for components
import BasePage from "../../components/basepage/BasePage";
import { routesLegalLinks, routesSiteMap } from "../routes";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <BasePage>
        <Switch>
          {Object.entries(routesSiteMap).map(([routeKey, route]) => {
            return (
              <Route key={`AppSiteMap_${routeKey}`} exact={route.exact} path={route.path} component={route.component} />
            );
          })}
          {Object.entries(routesLegalLinks).map(([routeKey, route]) => {
            return (
              <Route
                key={`AppLegalLinks_${routeKey}`}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </BasePage>
    </Router>
  );
}

export default App;
