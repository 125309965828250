//Imports for components
import Header from './Header';
import Footer from './Footer';
import Starfield from '../backgrounds/Starfield';

function BasePage(props: any) {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <Starfield className="flex-1" layers={10} minVelocity={20} maxVelocity={120} stars={300}>
                {props.children}
            </Starfield>
            <Footer />
        </div>
    );
}

export default BasePage;
