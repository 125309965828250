/* NOTE: if reCAPTCHA is used, the usage must be declared in the privacy policy
 *        AND the user must be asked for consent (cockies must be accepted as well)
 */

//Imports for npm packages
import React, { useEffect, useRef, useState } from "react";

//Imports for components
import ReCAPTCHAinitialize, { EstatusCode } from "./ReCAPTCHAinitialize";

export enum ESize {
  Normal = "normal",
  Compact = "compact",
}
export enum ETheme {
  Light = "light",
  Dark = "dark",
}

interface IReCaptchaProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  callback: (token: string | false | Error) => void;
  theme?: ETheme;
  size?: ESize;
  tabindex?: number;
  siteKeyV2: string;
}

export default function ReCaptchaV2(props: IReCaptchaProps) {
  //Declare the Hooks
  const ref = useRef<HTMLDivElement>(null);
  const [widgetId, setWidgetId] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState(EstatusCode.Idle);

  ReCAPTCHAinitialize(setStatus);

  useEffect(() => {
    // invoked by the widget when the user submits a successful response.
    function successCallback(token: string): void {
      props.callback(token);
    }

    //invoked by the widget when the response expired and the user needs to re-verify
    function expiredCallback(): void {
      props.callback(false);
    }

    //invoked by the widget when an error occurrs (usually network connectivity)
    function errorCallback(): void {
      props.callback(new Error());
    }

    const { theme, size, tabindex } = props;

    // make sure we haven't rendered this widget before and that the script is ready
    if (
      widgetId === undefined &&
      status === EstatusCode.Ready &&
      ref.current &&
      props.siteKeyV2
    ) {
      // render the widget and store the returned widget id in the state
      setWidgetId(
        grecaptcha.render(ref.current, {
          sitekey: props.siteKeyV2,
          callback: successCallback,
          "expired-callback": expiredCallback,
          "error-callback": errorCallback,
          theme,
          size,
          tabindex,
        })
      );
    }
  }, [props, status, widgetId]);

  const { theme, callback, size, tabindex, siteKeyV2, ...other } = props;

  return <div ref={ref} {...other} />;
}
