//Imports for npm packages
import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

//Imports for components
import ReCaptchaV2, { ESize, ETheme } from "../../components/reCAPTCHA/reCAPTCHA";
import { contactDetails } from "../legal/Address";
import "./Contact.css";
import { routesLegalLinks } from "../../domains/routes";

enum EreCAPTCHAstatus {
  VALID = "valid",
  EXPIRED = "expired",
  ERROR = "error",
}

enum EbuttonStatus {
  SUBMIT = "Submit",
  SENDING = "Sending...",
  SUCCESS = "Message was send successfully.",
}

enum EerrorElement {
  NAME = "name",
  EMAIL = "email",
  MESSAGE = "message",
  CAPTCHA = "captcha",
  NETWORK = "network",
}

enum EinputType {
  TEXT = "text",
  TEXTAREA = "textarea",
}

enum EinputElement {
  INPUT = "input",
  TEXTAREA = "textarea",
}

interface ImessageContent {
  name: string;
  email: string;
  message: string;
  token: string | undefined;
}

interface IformComponent {
  elementTag: EinputElement;
  type: EinputType;
  id: string;
  label: string;
  minLength: number;
  maxLength: number;
  specialValidation?: (value: string) => string;
  errorElement: EerrorElement;
  placeholder: string;
  props?: Record<string, string | number>;
}

function formValidation(
  label: string,
  stingObj: string,
  minLength: number,
  maxLength?: number,
  specialValidation?: (value: string) => string
): string {
  const length = stingObj.length ?? 0;
  let validationMessage = "";

  if (length < minLength) {
    validationMessage = `Your ${label} is too short. Please enter a ${label} that has more than ${minLength} character${
      minLength > 1 ? "s" : ""
    }.`;
  }
  if (specialValidation) {
    validationMessage = specialValidation(stingObj);
  }
  if (maxLength && length > maxLength) {
    validationMessage = `Your ${label} is too long. Please enter a ${label} that has less than ${maxLength} characters.`;
  }

  return validationMessage;
}

const inputFormElements: IformComponent[] = [
  {
    elementTag: EinputElement.INPUT,
    type: EinputType.TEXT,
    id: "name",
    label: "Name",
    placeholder: "Your Name",
    minLength: 1,
    maxLength: 70,
    errorElement: EerrorElement.NAME,
  },
  {
    elementTag: EinputElement.INPUT,
    type: EinputType.TEXT,
    id: "email",
    label: "Email",
    placeholder: "your Email",
    minLength: 1,
    maxLength: 320,
    errorElement: EerrorElement.EMAIL,
    specialValidation: (email: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        return `Please enter a valid Email address. Your Email must follow the RFC 5322 Official Standard`;
      } else {
        return "";
      }
    },
  },
  {
    elementTag: EinputElement.TEXTAREA,
    type: EinputType.TEXTAREA,
    id: "message",
    label: "Message",
    placeholder: "Your message",
    minLength: 100,
    maxLength: 10000,
    errorElement: EerrorElement.MESSAGE,
    props: { rows: 6 },
  },
];

function Contact() {
  //Google reCAPTCHE Site Key for flognity.com
  const recaptchaSiteKey = "6LdzKE0aAAAAAIDd7Z5dtMDgmdybSmKp0AYhH-Nr";
  //react states
  const [windowWidth, setWindowWidth] = useState(0);
  const [buttonStatus, setButtonStatus] = useState<EbuttonStatus>(EbuttonStatus.SUBMIT);
  const [reCAPTCHAstatus, setReCAPTCHAstatus] = useState<EreCAPTCHAstatus>(EreCAPTCHAstatus.EXPIRED);
  const [userConsent, setUserConsent] = useState(false);
  const errorDefaults: Record<EerrorElement, String> = Object.assign(
    {},
    ...Object.values(EerrorElement).map((element) => ({ [element]: "" }))
  );
  const [errorMessage, setErrorMessage] = useState<Record<EerrorElement, String>>(errorDefaults);
  const [messageContent, setMessageContent] = useState<ImessageContent>({
    name: "",
    email: "",
    message: "",
    token: undefined,
  });
  const [doValidateMessage, setDoValidateMessage] = useState<ImessageContent>({
    name: "",
    email: "",
    message: "",
    token: undefined,
  });
  const [focusedInput, setFocusedInput] = useState("");

  function handleConsentCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setUserConsent(e.target.checked);
    if (e.target.checked) {
      //The user can only consent once per session
      e.target.disabled = true;
    }
  }

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    label: string,
    minLength: number,
    errorElement: EerrorElement,
    maxLength?: number
  ) {
    setMessageContent((prevMessage) => ({
      ...prevMessage,
      [e.target.id]: e.target.value,
    }));
    //Only validate onChange if this Element has been edited before
    if ((doValidateMessage as any)[e.target.id] !== "") {
      setErrorMessage((prevMessage) => ({
        ...prevMessage,
        [errorElement]: formValidation(label, e.target.value, minLength, maxLength),
      }));
    }
  }

  function validateFormInput(details: {
    name: string;
    email: string;
    message: string;
    token: string | undefined;
  }): boolean {
    let validationMessage = "";
    let errorObject = errorDefaults;
    let isValid = true;
    //check all the input forms for validity
    inputFormElements.forEach((inputElement) => {
      validationMessage = formValidation(
        inputElement.label,
        (details as any)[inputElement.id],
        inputElement.minLength,
        inputElement.maxLength
      );

      (errorObject as any)[inputElement.id] = validationMessage;

      if (validationMessage !== "") {
        isValid = false;
      }
    });

    if (reCAPTCHAstatus !== EreCAPTCHAstatus.VALID && details.token === undefined) {
      errorObject.captcha = `Please confirm that you are not a robot by passing the CAPTCHA.`;
      isValid = false;
    }

    setErrorMessage(errorObject);

    return isValid;
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (validateFormInput(messageContent)) {
      setButtonStatus(EbuttonStatus.SENDING);

      await fetch("https://www.flognity.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(messageContent),
      })
        .then(async (response) => {
          let status_code = 0;
          try {
            status_code = response.status;
          } catch (e) {
            alert("Error in async response: " + e); // Failed to fetch
            //TODO: display error message to try again later
            setErrorMessage((prevMessage) => ({
              ...prevMessage,
              [EerrorElement.NETWORK]: `A network error occured. Your message was NOT sent. Please try again.`,
            }));

            setButtonStatus(EbuttonStatus.SUBMIT);
          }

          if (status_code === 200) {
            //The server responded OK. Now check the content of the response
            response.json().then(function (resData) {
              switch (resData.mailStatus) {
                case "INVALID MESSAGE":
                  setErrorMessage((prevMessage) => ({
                    ...prevMessage,
                    [EerrorElement.NETWORK]: `The sever responded: ${resData.error} Please try again.`,
                  }));
                  setButtonStatus(EbuttonStatus.SUBMIT);
                  break;

                case "MAIL SEND":
                  setErrorMessage(errorDefaults);
                  setButtonStatus(EbuttonStatus.SUCCESS);
                  break;

                case "INVALID CAPTCHA":
                  setReCAPTCHAstatus(EreCAPTCHAstatus.ERROR);
                  setMessageContent((prevMessage) => ({
                    ...prevMessage,
                    token: undefined,
                  }));
                  setErrorMessage((prevMessage) => ({
                    ...prevMessage,
                    [EerrorElement.CAPTCHA]: `Your CAPTCHA was invalid. Please try again and confirm that you are not a robot.`,
                  }));
                  setButtonStatus(EbuttonStatus.SUBMIT);
                  break;

                case "NO CAPTCHA RESPONSE":
                  setReCAPTCHAstatus(EreCAPTCHAstatus.ERROR);
                  setMessageContent((prevMessage) => ({
                    ...prevMessage,
                    token: undefined,
                  }));
                  setErrorMessage((prevMessage) => ({
                    ...prevMessage,
                    [EerrorElement.CAPTCHA]: `Goolge server error: ${resData.error} Your message was NOT sent. Please try again or send an email directly.`,
                  }));
                  setButtonStatus(EbuttonStatus.SUBMIT);
                  break;
                case "MAILSERVER ERROR":
                  setErrorMessage((prevMessage) => ({
                    ...prevMessage,
                    [EerrorElement.NETWORK]: `${resData.error} Your message was NOT sent. Please send an email directly.`,
                  }));
                  setButtonStatus(EbuttonStatus.SUBMIT);
                  break;

                default:
                  setErrorMessage((prevMessage) => ({
                    ...prevMessage,
                    [EerrorElement.NETWORK]: `The sever didn't respond as expected. Your message might not have been sent. Please send an email directly.`,
                  }));
                  setButtonStatus(EbuttonStatus.SUBMIT);
              }
            });
          } else {
            setErrorMessage((prevMessage) => ({
              ...prevMessage,
              [EerrorElement.NETWORK]: `The sever did not confirm a succefful Email submission. Your message was NOT sent. Please try again.`,
            }));
            setButtonStatus(EbuttonStatus.SUBMIT);
          }
        })
        .catch((e) => {
          setErrorMessage((prevMessage) => ({
            ...prevMessage,
            [EerrorElement.NETWORK]: `A network error occured. Your message was NOT sent. Please try again.`,
          }));

          setButtonStatus(EbuttonStatus.SUBMIT);
        });
    }
  };

  function v2Callback(token: string | false | Error): void {
    if (typeof token === "string") {
      setReCAPTCHAstatus(EreCAPTCHAstatus.VALID);
      setMessageContent((prevMessage) => ({
        ...prevMessage,
        token: token,
      }));
      setErrorMessage((prevMessage) => ({
        ...prevMessage,
        [EerrorElement.CAPTCHA]: "",
      }));
    } else if (typeof token === "boolean" && !token) {
      setReCAPTCHAstatus(EreCAPTCHAstatus.EXPIRED);
      setMessageContent((prevMessage) => ({
        ...prevMessage,
        token: undefined,
      }));
      setErrorMessage((prevMessage) => ({
        ...prevMessage,
        [EerrorElement.CAPTCHA]: `Your CAPTCHA has expired. Please confirm agan tha you are not a robot.`,
      }));
    } else if (token instanceof Error) {
      console.log("Error. please check your network connection");
      setReCAPTCHAstatus(EreCAPTCHAstatus.ERROR);
      setMessageContent((prevMessage) => ({
        ...prevMessage,
        token: undefined,
      }));
    }
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
  }, []); // Only re-run effect once on load

  return (
    <div className="px-general pb-12">
      <div className="text-center my-6 mx-6 md:mx-16 xl:mx-40">
        <h1 className="text-6xl md:text-7xl xl:text-8xl pb-12">Get In Touch</h1>
        <h2 className="text-1xl md:text-2xl xl:text-3xl pb-6">Looking to work together?</h2>
      </div>
      <div className="text-justify mx-auto pb-6 w-full xl:max-w-4xl 2xl:max-w-6xl">
        You need a full-stack software developer or you want to collaborate with me as a content creator? Just shoot me
        a message. You can use the contact from below. I will come back to you as soon as possible! Alternatively, you
        can also send an email directly to{" "}
        <a className="font-semibold rounded px-1" href={`mailto:${contactDetails.email}`}>
          {contactDetails.email}
        </a>
      </div>

      {/* Contact from with reCAPTCHA */}

      <div className=" mx-auto w-full px-8 m-8 pt-8 xl:max-w-4xl 2xl:max-w-6xl bg-blueGray-800 bg-opacity-90 border-2 rounded-3xl flex flex-col shadow-md">
        <h2 className="text-lg mb-1 font-medium title-font">Contact me</h2>
        <div className="leading-relaxed mb-5 ">Fill out the contact form and submit to send me a message.</div>
        <form onSubmit={handleSubmit}>
          {inputFormElements.map((inputElement, index) => {
            const CustomTag = inputElement.elementTag;
            return (
              <div key={`InputElement_${index}`} className="mb-4">
                <label htmlFor={inputElement.id} className="leading-7 text-sm">
                  {inputElement.label}
                </label>
                <CustomTag
                  type={inputElement.type}
                  id={inputElement.id}
                  required
                  className={`form-input ${
                    (errorMessage as any)[inputElement.id] !== ""
                      ? "border-red-600 focus:border-red-700 focus:ring-red-600 border-2"
                      : "border-blueGray-300 focus:border-teal-700 focus:ring-teal-600 border"
                  }`}
                  disabled={buttonStatus !== EbuttonStatus.SUBMIT}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                    handleInputChange(
                      e,
                      inputElement.label,
                      inputElement.minLength,
                      inputElement.errorElement,
                      inputElement.maxLength
                    )
                  }
                  onFocus={() => setFocusedInput(inputElement.id)}
                  placeholder={inputElement.placeholder}
                  onBlur={() => {
                    setDoValidateMessage((prevMessage) => ({
                      ...prevMessage,
                      [inputElement.id]: "true",
                    }));
                    setErrorMessage((prevMessage) => ({
                      ...prevMessage,
                      [inputElement.errorElement]: formValidation(
                        inputElement.label,
                        (messageContent as any)[inputElement.id],
                        inputElement.minLength,
                        inputElement.maxLength,
                        inputElement.specialValidation || undefined
                      ),
                    }));
                  }}
                  {...inputElement.props}></CustomTag>

                <div
                  className={`${focusedInput === inputElement.id ? "block" : "hidden"} text-right pr-2 text-sm -mb-5  ${
                    (errorMessage as any)[inputElement.id] !== ""
                      ? "text-red-600 font-semibold"
                      : "text-warmGray-500 font-light"
                  }`}>
                  {(messageContent as any)[inputElement.id].length} / {inputElement.maxLength}
                </div>
              </div>
            );
          })}
          <div className="relative mb-4">
            <div className="flex flex-row items-top">
              <input
                type="checkbox"
                required
                id="reCAPTCHAConsent"
                name="reCAPTCHAConsent"
                value="false"
                className="transform scale-150 mr-2 ml-1 mt-2"
                checked={userConsent}
                onChange={handleConsentCheckbox}
              />
              <label className="ml-2">
                I have read and accept the{" "}
                <NavLink
                  to={routesLegalLinks.PrivacyStatement.path}
                  exact={routesLegalLinks.PrivacyStatement.exact}
                  className="underline whitespace-nowrap">
                  Terms of Use and Privacy policy
                </NavLink>
                .
                <aside className="font-light">
                  <em>
                    Your consent is necessary for this form because Google reCAPTCHA is being used to check and prevent
                    automated bots from accessing and interacting with this website. Your consent will not be saved for
                    your next visit, that is after reloading this website.
                  </em>
                </aside>
              </label>
            </div>
            <div>
              {userConsent && buttonStatus === EbuttonStatus.SUBMIT && (
                <div className="mt-2">
                  <ReCaptchaV2
                    callback={v2Callback}
                    theme={ETheme.Dark}
                    size={windowWidth > 450 ? ESize.Normal : ESize.Compact}
                    tabindex={0}
                    siteKeyV2={recaptchaSiteKey}
                  />
                </div>
              )}
              <button
                type="submit"
                className={`flex flex-row place-items-center bg-cyan-700 border-0 py-2 mb-12 px-6 mt-4 focus:outline-none hover:bg-cyan-500 hover:text-warmGray-700 rounded text-lg ${
                  buttonStatus !== EbuttonStatus.SUCCESS ? "block" : "hidden"
                }`}>
                <svg
                  className={`animate-spin h-5 w-5 mr-3 ${buttonStatus === EbuttonStatus.SENDING ? "block" : "hidden"}`}
                  viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>{buttonStatus}</span>
              </button>
              {buttonStatus === EbuttonStatus.SUCCESS && (
                <div className="mt-2 text-teal-500 font-bold">
                  Thank you for your submission! I will come back to you soon.
                </div>
              )}
              {Object.values(errorMessage).map((error, index) => {
                return (
                  <Fragment key={`ErrorText_${index}`}>
                    {error && (
                      <div key={`Error_${index}`} className="-mt-3 pt-4 text-red-600 font-normal">
                        {error}
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
