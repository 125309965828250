//Imports for npm packages
import React, { Fragment, useState } from "react";
//Imports for components
import { IconCalendar, IconChevronDown, IconChevronUp, IconLocationMarker } from "../../components/icons/Heroicons";
import {
  IconSvelte,
  IconCPP,
  IconCSS3,
  IconExpress,
  IconGit,
  IconHTML5,
  IconIBM,
  IconJava,
  IconJavaScript,
  IconMSSQL,
  IconNodeJs,
  IconPerl,
  IconPython,
  IconReact,
  IconSVN,
  IconTailwindCSS,
  IconTypeScript,
  IconMySQL,
  IconCSharp,
  IconDotNet,
  IconSQLite,
  IconAzureDevOps,
  IconAzurePipelines,
  IconGitKraken,
  IconVisualStudio,
  IconNotePadPlusPlus,
} from "../../components/icons/SimpleIcons";

const skillSet = {
  html: { icon: IconHTML5, name: "HTML" },
  css: { icon: IconCSS3, name: "CSS" },
  tailwindCSS: { icon: IconTailwindCSS, name: "Tailwind CSS" },
  javaScript: { icon: IconJavaScript, name: "JavaScript" },
  typeScript: { icon: IconTypeScript, name: "TypeScript" },
  react: { icon: IconReact, name: "React" },
  svelte: { icon: IconSvelte, name: "Svelte" },
  nodeJs: { icon: IconNodeJs, name: "NodeJS" },
  express: { icon: IconExpress, name: "Express" },
  msSql: { icon: IconMSSQL, name: "Microsoft SQL Server" },
  sqLite: { icon: IconSQLite, name: "SQLite" },
  sybase: { name: "Sybase" },
  iib: { icon: IconIBM, name: "IBM Integration Bus" },
  perl: { icon: IconPerl, name: "Perl" },
  python: { icon: IconPython, name: "Python" },
  unixBash: { name: "UNIX Bash" },
  WinBatch: { name: "Windows Batch Script" },
  xslt: { name: "XSLT" },
  mxml: { name: "MxML Exchange" },
  datamart: { name: "Datamart" },
  mReport: { name: "MReports" },
  udf: { name: "User Defined Tables" },
  git: { icon: IconGit, name: "git" },
  svn: { icon: IconSVN, name: "SVN" },
  cvs: { name: "CVS" },
  azureDevOps: { icon: IconAzureDevOps, name: "Azure DevOps" },
  azurePipelines: { icon: IconAzurePipelines, name: "Azure Pipelines" },
  gitKraken: { icon: IconGitKraken, name: "GitKraken" },
  cPP: { icon: IconCPP, name: "C++" },
  cSharp: { icon: IconCSharp, name: "C#" },
  java: { icon: IconJava, name: "Java" },
  mySQL: { icon: IconMySQL, name: "MySQL" },
  dotNet: { icon: IconDotNet, name: ".NET" },
  matLab: { name: "MATLAB" },
  iBMesql: { icon: IconIBM, name: "IBM ESQL" },
  iBMWebSphereMQ: { icon: IconIBM, name: "IBM WebSphere MQ" },
  visualStudio: { icon: IconVisualStudio, name: "Visual Studio" },
  notepadPP: { icon: IconNotePadPlusPlus, name: "Notepad++" },
};

function ExperienceTile(props: {
  company: string;
  location: string;
  dates: string;
  jobTitle: string;
  description: string;
  skills?: { category?: string; items: { icon?: any; name: string }[] }[];
}) {
  return (
    <div className="w-full h-full pr-6 pl-6 pb-2 pt-2 m-2 mb-6 rounded-3xl border-t-2 border-b-2 flex flex-col relative shadow-2x bg-blueGray-800 bg-opacity-30 break-words">
      <div className="flex-1 ">
        <div className="pb-4">
          {/* Company name */}
          <h2 className="tracking-wider mb-1 font-medium">{props.company}</h2>
          {/* Job Title */}
          <h3 className="mb-1 font-medium">{props.jobTitle}</h3>
          {/* Dates */}
          <div className="flex flex-row">
            {/* Heroicon calendar */}
            <IconCalendar className="w-5 pt-1" />
            <div className="pl-2">{props.dates}</div>
          </div>
          {/* Location */}
          <div className="flex flex-row pt-2">
            {/* Heroicon location-marker*/}
            <IconLocationMarker className="w-5 pt-1" />
            <div className="pl-2">{props.location}</div>
          </div>
        </div>
        <hr className="border-opacity-50" />
        <div className="flex flex-col">
          {/* Job description */}
          <div className="pr-2">
            <h4>Details:</h4>
            <div>{props.description}</div>
          </div>
          {/* Skills */}
          {props.skills && (
            <Fragment>
              <h4 className="pt-6">Required and acquired skills:</h4>
              <div className="flex flex-wrap">
                {props.skills.map((skillCategory, i) => {
                  return (
                    <div key={`skillCategory_${i}`} className="pr-12">
                      {skillCategory.category && <h3 className="pt-6">{skillCategory.category}:</h3>}
                      <div className="flex flex-wrap">
                        {skillCategory.items.map((skill, i) => {
                          return (
                            <div
                              key={`Skill_${i}`}
                              className="flex flex-row p-2 mr-2 mb-2 mt-2 items-center rounded-lg border-2 bg-cyan-800 bg-opacity-80">
                              {skill.icon && <skill.icon className="w-5 bt-1 overflow-visible" />}
                              <div className="pl-2">{skill.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default function SoftwareDeveloper() {
  const [isExperienceShown, setExperienceShown] = useState(true);
  return (
    <section className="bg-blueGray-900 rounded-3xl bg-opacity-30">
      <div
        className="pl-6 mt-12 mb-2 align-top bg-blueGray-900 rounded-3xl bg-opacity-60 flex flex-row items-center justify-start cursor-pointer text-warmGray-300 hover:text-warmGray-50 focus:text-warmGray-50 focus:outline-none"
        onClick={() => {
          setExperienceShown(!isExperienceShown);
        }}>
        {/* div with the icon */}
        <div className="p-2">
          <IconChevronUp className={`h-6 w-6 fill-current border-2 rounded-md ${!isExperienceShown && "hidden"}`} />
          <IconChevronDown className={`h-6 w-6 fill-current border-2 rounded-md ${isExperienceShown && "hidden"}`} />
        </div>
        <h2 className="text-center text-2xl tracking-widest">Experience</h2>
      </div>
      <div
        className={`${
          isExperienceShown ? "transition duration-100 ease-in-out transform scale-y-100" : "transform scale-y-0 h-0"
        }`}>
        <div className="flex flex-wrap">
          <ExperienceTile
            dates="January 2022 - present day"
            company="USNR"
            jobTitle="Software Developer"
            location="Salmon Arm, BC, Canada"
            description="Software developer for scanning and optimization systems of autonomous wood
                         processing equipment and technology. Design, development and refinement of 
                         sophisticated algorithms in C/C++ to determine the most valuable combination 
                         of products. Optimization of computing time to allow for speedups in automated 
                         decision finding resulting in faster processing of lumber. Translating 
                         optimization decisions into messages received from and sent to Programmable 
                         Logic Controllers (PLC) resulting in physical machine instructions. Writing 
                         PLC specifications for newly developed or upgraded features and machines."
            skills={[
              {
                category: "Software Development",
                items: [skillSet.dotNet, skillSet.cPP, skillSet.cSharp],
              },
              {
                category: "Databases",
                items: [skillSet.msSql, skillSet.sqLite],
              },
              {
                category: "Scripting",
                items: [skillSet.WinBatch],
              },
              {
                category: "Version Control and Tools",
                items: [
                  skillSet.azureDevOps,
                  skillSet.azurePipelines,
                  skillSet.git,
                  skillSet.gitKraken,
                  skillSet.visualStudio,
                  skillSet.notepadPP,
                ],
              },
            ]}
          />
          <ExperienceTile
            dates="April 2017 - July 2021"
            company="Helaba"
            jobTitle="Application Developer"
            location="Frankfurt am Main, Germany"
            description="Application developer with a diversified technology stack to
                design, implement and test new software applications and
                interfaces for the business management and for front office
                systems of the trading desk. This includes, but is not limited to,
                Murex technologies (like MxML Exchange, Datamart, MReport and User
                Defined Tables), scripting languages such as perl, python und UNIX
                shell as well as Middleware like IBM Integration Bus and
                MQ-Series. Experiences with technologies for stand-alone and
                full-stack web applications include TypeScript, React,
                tailwindCSS, NodeJS, typeORM, Microsoft SQL Server, C#, Microsoft
                .NET, JQuery and XSLT."
            skills={[
              {
                category: "Full-Stack Web and Software Development",
                items: [
                  skillSet.html,
                  skillSet.css,
                  skillSet.tailwindCSS,
                  skillSet.javaScript,
                  skillSet.typeScript,
                  skillSet.cSharp,
                  skillSet.react,
                  skillSet.svelte,
                  skillSet.dotNet,
                  skillSet.nodeJs,
                  skillSet.express,
                ],
              },
              {
                category: "Databases",
                items: [skillSet.msSql, skillSet.sybase],
              },
              {
                category: "Middleware",
                items: [skillSet.iib, skillSet.iBMesql, skillSet.iBMWebSphereMQ],
              },
              {
                category: "Scripting",
                items: [skillSet.perl, skillSet.python, skillSet.unixBash, skillSet.WinBatch, skillSet.xslt],
              },
              {
                category: "Murex",
                items: [skillSet.mxml, skillSet.datamart, skillSet.mReport, skillSet.udf],
              },
              {
                category: "Version Control",
                items: [skillSet.git, skillSet.svn, skillSet.cvs],
              },
            ]}
          />
          <ExperienceTile
            dates="April 2016 - September 2016"
            company="Goethe-University Frankfurt"
            jobTitle="Application Developer"
            location="Frankfurt am Main, Germany"
            description="Student Associate for the Chair of Investment, Portfolio Management
            and Pension Finance. Development of a cross-platform application in
            python to determine optimal asset-allocation by using
            Monte-Carlo-simulations. Implementation of a mulit-dimensional
            spline-interpolation in C++."
            skills={[
              {
                category: "Mobile App Development",
                items: [skillSet.html, skillSet.css, skillSet.python],
              },

              {
                category: "Scientific Programming",
                items: [skillSet.cPP, skillSet.matLab, skillSet.unixBash],
              },

              {
                category: "Version Control",
                items: [skillSet.git],
              },
            ]}
          />

          <ExperienceTile
            dates="June 2015 - March 2016"
            company="Adento AG"
            jobTitle="Interim Chief Technical Officer"
            location="Eschborn, Germany"
            description="Restructuring, support and optimization of IT-related assets as well
            as cost and budget planning"
            skills={[
              {
                category: "",
                items: [
                  skillSet.java,
                  skillSet.cPP,
                  skillSet.mySQL,
                  skillSet.javaScript,
                  skillSet.css,
                  skillSet.unixBash,
                  skillSet.git,
                ],
              },
            ]}
          />
        </div>
      </div>
    </section>
  );
}
