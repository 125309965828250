//Imports for npm packages
import React from "react";
import { NavLink } from "react-router-dom";
//Imports for components
import { contactDetails, CurrentAddressBr } from "./Address";
import { routesSiteMap } from "../routes";
import "./PrivacyStatement.css";

function PrivacyStatement() {
  const ContactRoute = routesSiteMap.Contact;

  return (
    <div
      id="PrivacyPolicy"
      className="py-general px-general mx-auto w-full xl:max-w-4xl 2xl:max-w-7xlx-4 sm:px-8 md:px-12 lg:px-16 xl:px-32">
      <h1>Privacy Policy</h1>
      <h4>March 4th, 2023</h4>
      {/* introduction */}
      <div>
        This privacy notice for flognity.com ('we', 'us', or 'our'), describes how and why we might collect, store, use,
        and/or share ('process') your information when you use our services ('Services'), such as when you:
        <ul className="list-outside py-2 ml-8 list-disc">
          <li>
            Visit our website at <a href={contactDetails.website.url}>{contactDetails.website.name}</a>, or any website
            of ours that links to this privacy notice
          </li>
          <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        </ul>
        <strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy rights
        and choices. If you do not agree with our policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at {contactDetails.email}.
      </div>
      {/* summary */}
      <section className="py-3">
        <h2>SUMMARY OF KEY POINTS</h2>
        <p>
          <strong>
            This summary provides key points from our privacy notice, but you can find out more details about any of
            these topics by clicking the link following each key point or by using our table of contents below to find
            the section you are looking for. You can also click <a href="#toc">here</a> to go directly to our table of
            contents.
          </strong>
        </p>
        <p className="py-4">
          <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we
          may process personal information depending on how you interact with{" "}
          <a href={contactDetails.website.url}>{contactDetails.website.name}</a> and the Services, the choices you make,
          and the products and features you use. Click <a href="#personalInformation">here</a> to learn more.
        </p>
        <p className="py-4">
          <strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal
          information.
        </p>
        <p className="py-4">
          <strong>Do we receive any information from third parties?</strong> We do not receive any information from
          third parties.
        </p>
        <p className="py-4">
          <strong>How do we process your information?</strong> We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We
          may also process your information for other purposes with your consent. We process your information only when
          we have a valid legal reason to do so. Click <a href="#whatDoWeProcess">here</a> to learn more.
        </p>
        <p className="py-4">
          <strong>In what situations and with which parties do we share personal information?</strong> We may share
          information in specific situations and with specific third parties. Click{" "}
          <a href="#whenAndWithWhomDoWeShareInformation">here</a> to learn more.
        </p>
        <p className="py-4">
          <strong>How do we keep your information safe?</strong> We have organisational and technical processes and
          procedures in place to protect your personal information. However, no electronic transmission over the
          internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
          guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our
          security and improperly collect, access, steal, or modify your information. Click{" "}
          <a href="#howDoWeKeepYourInformationSave">here</a> to learn more.
        </p>
        <p className="py-4">
          <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable
          privacy law may mean you have certain rights regarding your personal information. Click{" "}
          <a href="#whatAreYourPrivacyRights">here</a> to learn more.
        </p>
        <p className="py-4">
          <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by contacting
          us. We will consider and act upon any request in accordance with applicable data protection laws. Click{" "}
          <a href="#HowCanYouContactUsAboutThisNotice">here</a> to learn how to contact us.
        </p>
        <p className="py-4">
          Want to learn more about what <a href={contactDetails.website.url}>{contactDetails.website.name}</a> does with
          any information we collect? Click <a href="#toc">here</a> to review the notice in full.
        </p>
      </section>

      {/* table of contents */}
      <section id="toc" className="py-3">
        <h2>TABLE OF CONTENTS</h2>
        <ul className="list-outside py-2 ml-8 list-none">
          <li>
            <a href="#whatDoWeCollect">1. WHAT INFORMATION DO WE COLLECT?</a>
          </li>
          <li>
            <a href="#whatDoWeProcess">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#whatLegalBasesDoWeRelyOn">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#whenAndWithWhomDoWeShareInformation">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </li>
          <li>
            <a href="#doWeUseCookes">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
          </li>
          <li>
            <a href="#howLongDoWeKeepInformation">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#howDoWeKeepYourInformationSave">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
          </li>
          <li>
            <a href="#doWeCollectInformationFromMinors">8. DO WE COLLECT INFORMATION FROM MINORS?</a>
          </li>
          <li>
            <a href="#whatAreYourPrivacyRights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
          </li>
          <li>
            <a href="#controlsForDoNotTrackFeatures">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
          </li>
          <li>
            <a href="#doClaiforniaResidentsHaveSpecificPrivacyRights">
              11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#doVirginiaResidentsHaveSpecificPrivacyRights">
              12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#doWeMakeUpdatesToThisNotice">13. DO WE MAKE UPDATES TO THIS NOTICE?</a>
          </li>
          <li>
            <a href="#HowCanYouContactUsAboutThisNotice">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
          </li>
          <li>
            <a href="#howCanYouReviewUpdateDeleteCollectedData">
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            </a>
          </li>
          <li>
            <a href="#presenceOnOtherPlatforms">16. PRESENCE ON OTHER PLATFORMS, SERVICES AND WEBSITES</a>
          </li>
        </ul>
      </section>
      {/* 1. WHAT INFORMATION DO WE COLLECT? */}
      <section id="whatDoWeCollect" className="py-3">
        <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
        <h3 id="personalInformation">Personal information you disclose to us</h3>
        <p className="italic py-4">
          <strong>In Short:</strong> We collect personal information that you provide to us.
        </p>
        <p className="py-4">
          We collect personal information that you voluntarily provide to us when you express an interest in obtaining
          information about us or our products and Services, when you participate in activities on the Services, or
          otherwise when you contact us.
        </p>
        <div className="py-4">
          <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the
          context of your interactions with us and the Services, the choices you make, and the products and features you
          use. The personal information we collect may include the following:
          <ul className="list-inside ml-8 list-disc">
            <li>names</li>
            <li>email addresses</li>
          </ul>
        </div>
        <p className="py-4">
          <strong>Sensitive Information.</strong> We do not process sensitive information.
        </p>
        <p className="py-4">
          All personal information that you provide to us must be true, complete, and accurate, and you must notify us
          of any changes to such personal information.
        </p>
        <h3>Information automatically collected</h3>
        <p className="italic py-4">
          <strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and
          device characteristics — is collected automatically when you visit our Services.
        </p>
        <p className="py-4">
          We automatically collect certain information when you visit, use, or navigate the Services. This information
          does not reveal your specific identity (like your name or contact information) but may include device and
          usage information, such as your IP address, browser and device characteristics, operating system, language
          preferences, referring URLs, device name, country, location, information about how and when you use our
          Services, and other technical information. This information is primarily needed to maintain the security and
          operation of our Services, and for our internal analytics and reporting purposes.
        </p>
        <p className="py-4">
          Like many businesses, we also collect information through cookies and similar technologies.
        </p>
        <div className="py-4">
          The information we collect includes:
          <ul className="list-outside ml-8 list-disc">
            <li className="py-2">
              <i>Log and Usage Data.</i> Log and usage data is service-related, diagnostic, usage, and performance
              information our servers automatically collect when you access or use our Services and which we record in
              log files. Depending on how you interact with us, this log data may include your IP address, device
              information, browser type, and settings and information about your activity in the Services (such as the
              date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take
              such as which features you use), device event information (such as system activity, error reports
              (sometimes called 'crash dumps'), and hardware settings).
            </li>
            <li className="py-2">
              <i>Device Data.</i> We collect device data such as information about your computer, phone, tablet, or
              other device you use to access the Services. Depending on the device used, this device data may include
              information such as your IP address (or proxy server), device and application identification numbers,
              location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system,
              and system configuration information.
            </li>
            <li className="py-2">
              <i>Location Data.</i> We collect location data such as information about your device's location, which can
              be either precise or imprecise. How much information we collect depends on the type and settings of the
              device you use to access the Services. For example, we may use GPS and other technologies to collect
              geolocation data that tells us your current location (based on your IP address). You can opt out of
              allowing us to collect this information either by refusing access to the information or by disabling your
              Location setting on your device. However, if you choose to opt out, you may not be able to use certain
              aspects of the Services
            </li>
          </ul>
        </div>
      </section>
      {/* 2. HOW DO WE PROCESS YOUR INFORMATION? */}
      <section id="whatDoWeProcess" className="py-3">
        <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> We process your information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </p>

        <div>
          <strong>
            We process your personal information for a variety of reasons, depending on how you interact with our
            Services, including:
          </strong>

          <ul className="list-outside ml-8 list-disc">
            <li className="py-2">
              <strong>To respond to user inquiries/offer support to users.</strong> We may process your information to
              respond to your inquiries and solve any potential issues you might have with the requested service.
            </li>
            <li className="py-2">
              <strong>To protect our Services.</strong> We may process your information as part of our efforts to keep
              our Services safe and secure, including fraud monitoring and prevention.
            </li>
            <li className="py-2">
              <strong>To save or protect an individual's vital interest.</strong> We may process your information when
              necessary to save or protect an individual’s vital interest, such as to prevent harm.
            </li>
          </ul>
        </div>
      </section>
      {/* 3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION? */}
      <section id="whatLegalBasesDoWeRelyOn" className="py-3">
        <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> We only process your personal information when we believe it is necessary and we
          have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply
          with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your
          rights, or to fulfil our legitimate business interests.
        </p>
        <p className="py-4 underline">
          <strong>If you are located in the EU or UK, this section applies to you.</strong>
        </p>
        <div className="-4">
          The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely
          on in order to process your personal information. As such, we may rely on the following legal bases to process
          your personal information:
          <ul className="list-outside ml-8 list-disc">
            <li className="py-2">
              <strong>Consent.</strong> We may process your information if you have given us permission (i.e. consent)
              to use your personal information for a specific purpose. You can withdraw your consent at any time. Click
              here to learn more.
            </li>
            <li className="py-2">
              <strong>Performance of a Contract.</strong> We may process your personal information when we believe it is
              necessary to fulfil our contractual obligations to you, including providing our Services or at your
              request prior to entering into a contract with you.
            </li>
            <li className="py-2">
              <strong>Legitimate Interests.</strong> We may process your information when we believe it is reasonably
              necessary to achieve our legitimate business interests and those interests do not outweigh your interests
              and fundamental rights and freedoms. For example, we may process your personal information for some of the
              purposes described in order to:
              <ul className="list-outside ml-8 list-disc">
                <li className="py-2">Diagnose problems and/or prevent fraudulent activities</li>
              </ul>
            </li>
            <li className="py-2">
              <strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for
              compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory
              agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in
              which we are involved.
            </li>
            <li className="py-2">
              <strong>Vital Interests.</strong> We may process your information where we believe it is necessary to
              protect your vital interests or the vital interests of a third party, such as situations involving
              potential threats to the safety of any person.
            </li>
          </ul>
        </div>
        <p className="py-4 underline">
          <strong>If you are located in Canada, this section applies to you.</strong>
        </p>
        <p className="-4">
          We may process your information if you have given us specific permission (i.e. express consent) to use your
          personal information for a specific purpose, or in situations where your permission can be inferred (i.e.
          implied consent). You can withdraw your consent at any time. Click <a href="#withdrawYourConsent">here</a> to
          learn more.
        </p>
        <p className="-4">
          In some exceptional cases, we may be legally permitted under applicable law to process your information
          without your consent, including, for example:
        </p>
        <ul className="list-outside ml-8 list-disc">
          <li className="py-2">
            If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
          </li>
          <li className="py-2">For investigations and fraud detection and prevention</li>
          <li className="py-2">For business transactions provided certain conditions are met</li>
          <li className="py-2">
            If it is contained in a witness statement and the collection is necessary to assess, process, or settle an
            insurance claim
          </li>
          <li className="py-2">For identifying injured, ill, or deceased persons and communicating with next of kin</li>
          <li className="py-2">
            If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
          </li>
          <li className="py-2">
            If it is reasonable to expect collection and use with consent would compromise the availability or the
            accuracy of the information and the collection is reasonable for purposes related to investigating a breach
            of an agreement or a contravention of the laws of Canada or a province
          </li>
          <li className="py-2">
            If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to
            the production of records
          </li>
          <li className="py-2">
            If it was produced by an individual in the course of their employment, business, or profession and the
            collection is consistent with the purposes for which the information was produced
          </li>
          <li className="py-2">If the collection is solely for journalistic, artistic, or literary purposes</li>
          <li className="py-2">If the information is publicly available and is specified by the regulations</li>
        </ul>
      </section>
      {/* 4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? */}
      <section id="whenAndWithWhomDoWeShareInformation" className="py-3">
        <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> We may share information in specific situations described in this section and/or
          with the following third parties.
        </p>
        <div className="py-4">
          <strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share your data with
          third-party vendors, service providers, contractors, or agents ('third parties') who perform services for us
          or on our behalf and require access to such information to do that work. The third parties we may share
          personal information with are as follows:
          <ul className="list-outside ml-8 list-disc">
            <li className="py-2">
              <strong>Cloud Computing Services</strong>
              <p className="py-4">Google Cloud Platform</p>
            </li>
            <li className="py-2">
              <strong>Content Optimisation</strong>
              <p className="py-4">YouTube video embed</p>
            </li>
            <li className="py-2">
              <strong>Social Media Sharing and Advertising</strong>
              <p className="py-4">YouTube social plugins</p>
            </li>
          </ul>
          <p>We also may need to share your personal information in the following situations:</p>
          <ul className="list-outside ml-8 list-disc">
            <li className="py-2">
              <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion
              of our business to another company.
            </li>
          </ul>
        </div>

        {/*Google reCAPTCHA */}
        <div>
          <h4>Google reCAPTCHA</h4>
          <p>
            Our website uses Google reCAPTCHA to check and prevent automated servers ("bots") from accessing and
            interacting with our website. This is a service provided by Google Ireland Limited, Gordon House, Barrow
            Street, Dublin 4, Irland (hereinafter: Google).
          </p>
          <p>
            This service allows Google to determine from which website your request has been sent and from which IP
            address the reCAPTCHA input box has been used. In addition to your IP address, Google may collect other
            information necessary to provide and guarantee this service.&nbsp;&nbsp;&nbsp;
          </p>
          <p>
            The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the security of our website
            and in the prevention of unwanted, automated access in the form of spam or similar.
          </p>
          <p>Google offers detailed information at</p>
          <p>
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
          </p>
          <p>concerning the general handling of your user data.</p>
        </div>
        {/*Google Analytics */}
        <div>
          <h4>Google Analytics</h4>
          <p>
            We use Google Analytics on our website. This is a web analytics service provided by Google Ireland Limited,
            Gordon House, Barrow Street, Dublin 4, Irland (hereinafter: Google).
          </p>
          <p>
            The Google Analytics service is used to analyze how our website is used. The legal basis is Art. 6 Para. 1
            lit. f) GDPR. Our legitimate interest lies in the analysis, optimization, and economic operation of our
            site.
          </p>
          <p>
            Usage and user-related information, such as IP address, place, time, or frequency of your visits to our
            website will be transmitted to a Google server in the United States and stored there. However, we use Google
            Analytics with the so-called anonymization function, whereby Google truncates the IP address within the EU
            or the EEA before it is transmitted to the US.
          </p>
          <p>
            The data collected in this way is in turn used by Google to provide us with an evaluation of visits to our
            website and what visitors do once there. This data can also be used to provide other services related to the
            use of our website and of the internet in general.
          </p>
          <p>
            Google states that it will not connect your IP address to other data. In addition, Google provides further
            information with regard to its data protection practices at
          </p>
          <p>
            <a href="https://www.google.com/intl/en/policies/privacy/partners" target="_blank" rel="noreferrer">
              https://www.google.com/intl/en/policies/privacy/partners,
            </a>
          </p>
          <p>including options you can exercise to prevent such use of your data.</p>
          <p>In addition, Google offers an opt-out add-on at</p>
          <p>
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">
              https://tools.google.com/dlpage/gaoptout?hl=en
            </a>
          </p>
          <p>
            in addition with further information. This add-on can be installed on the most popular browsers and offers
            you further control over the data that Google collects when you visit our website. The add-on informs Google
            Analytics JavaScript (ga.js) that no information about the website visit should be transmitted to Google
            Analytics. However, this does not prevent information from being transmitted to us or to other web analytics
            services we may use as detailed herein.
          </p>
        </div>
        {/*Google Fonts */}
        <div>
          <h4>Google Fonts</h4>
          <p>
            Our website uses Google Fonts to display external fonts. This is a service provided by Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Irland (hereinafter: Google).
          </p>
          <p>
            To enable the display of certain fonts on our website, a connection to the Google server in the USA is
            established whenever our website is accessed.
          </p>
          <p>
            The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the optimization and
            economic operation of our site.
          </p>
          <p>
            When you access our site, a connection to Google is established from which Google can identify the site from
            which your request has been sent and to which IP address the fonts are being transmitted for display.
          </p>
          <p>Google offers detailed information at</p>
          <p>
            <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noreferrer">
              https://adssettings.google.com/authenticated
            </a>
          </p>
          <p>
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
          </p>
          <p>in particular on options for preventing the use of data.</p>
        </div>
        {/*YouTube */}
        <div>
          <h4>YouTube</h4>
          <p>
            We use YouTube on our website. This is a video portal operated by YouTube LLC, 901 Cherry Ave, 94066 San
            Bruno, CA, USA, hereinafter referred to as "YouTube".
          </p>
          <p>
            YouTube is a subsidiary of Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
            hereinafter referred to as "Google".
          </p>
          <p>
            We use YouTube in its advanced privacy mode to show you videos. The legal basis is Art. 6 Para. 1 lit. f)
            GDPR. Our legitimate interest lies in improving the quality of our website. According to YouTube, the
            advanced privacy mode means that the data specified below will only be transmitted to the YouTube server if
            you actually start a video.
          </p>
          <p>
            Without this mode, a connection to the YouTube server in the USA will be established as soon as you access
            any of our web pages on which a YouTube video is embedded.
          </p>
          <p>
            This connection is required in order to be able to display the respective video on our website within your
            browser. YouTube will record and process at a minimum your IP address, the date and time the video was
            displayed, as well as the website you visited. In addition, a connection to the DoubleClick advertising
            network of Google is established.
          </p>
          <p>
            If you are logged in to YouTube when you access our site, YouTube will assign the connection information to
            your YouTube account. To prevent this, you must either log out of YouTube before visiting our site or make
            the appropriate settings in your YouTube account.
          </p>
          <p>
            For the purpose of functionality and analysis of usage behavior, YouTube permanently stores cookies on your
            device via your browser. If you do not agree to this processing, you have the option of preventing the
            installation of cookies by making the appropriate settings in your browser. Further details can be found in
            the section about cookies above.
          </p>
          <p>
            Further information about the collection and use of data as well as your rights and protection options in
            Google's privacy policy found at
          </p>
          <p>
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
          </p>
        </div>
        {/*Ads */}
        <div>
          <h4>Google AdSense and Google AdMob</h4>
          <p>
            We may use Google AdSense and/or Google AdMob on our websites and/or apps to integrate advertisements. This
            is a service provided by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (hereinafter:
            Google).
          </p>
          <p>
            AdMob is one of the world’s largest mobile advertising platforms and claims to serve more than 40 billion
            mobile banner and text ads per month across mobile Web sites and handset applications. Read about their
            privacy policy here:{" "}
            <a href="https://support.google.com/admob/answer/6128543" target="_blank" rel="noreferrer">
              https://support.google.com/admob/answer/6128543
            </a>
          </p>
          <p>
            Google AdSense stores cookies and web beacons on your device via your browser. This enables Google to
            analyze how you use our website. In addition to your IP address and the advertising formats displayed, the
            information thus collected will be transmitted to Google in the USA and stored there. Google may also share
            this information with third parties. Google states that it will not connect your IP address to other data.
          </p>
          <p>
            The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the analysis, optimization,
            and economic operation of our sites and apps.
          </p>
          <p>
            If you do not agree to this processing, you have the option of preventing the installation of cookies by
            making the appropriate settings in your browser. Further details can be found in the section about cookies
            above.
          </p>
          <p>In addition, Google offers an opt-out add-on at</p>
          <p>
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
          </p>
          <p>
            <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noreferrer">
              https://adssettings.google.com/authenticated
            </a>
          </p>
          <p>in particular on options for preventing the use of data.</p>

          <p>There are multiple opt-out options for users of our apps:</p>

          <ul className="list-disc ml-6">
            <li>
              Opt-out of all information collection by uninstalling the application: You can stop all collection of
              information by our apps by uninstalling them. You may use the standard uninstall processes which may be
              available as part of your mobile device or via the mobile application marketplace.
            </li>
            <li>
              You may at any time opt-out from the use of information to serve targeted advertising by advertisers
              and/or third party network advertisers by following the third party instructions directly:{" "}
              <a href="https://www.google.com/ads/preferences/html/mobile-about.html" target="_blank" rel="noreferrer">
                https://www.google.com/ads/preferences/html/mobile-about.html
              </a>
            </li>
          </ul>
        </div>
        {/*Social media links */}
        <div>
          <h4>Social media links via graphics</h4>
          <p>
            We also integrate the following social media sites into our website. The integration takes place via a
            linked graphic of the respective site. The use of these graphics stored on our own servers prevents the
            automatic connection to the servers of these networks for their display. Only by clicking on the
            corresponding graphic will you be forwarded to the service of the respective social network.
          </p>
          <p>
            Once you click, that network may record information about you and your visit to our site. It cannot be ruled
            out that such data will be processed in the United States.
          </p>
          <p>
            Initially, this data includes such things as your IP address, the date and time of your visit, and the page
            visited. If you are logged into your user account on that network, however, the network operator might
            assign the information collected about your visit to our site to your personal account. If you interact by
            clicking Like, Share, etc., this information can be stored your personal user account and possibly posted on
            the respective network. To prevent this, you need to log out of your social media account before clicking on
            the graphic. The various social media networks also offer settings that you can configure accordingly.
          </p>
          <p>The following social networks are integrated into our site by linked graphics:</p>
          <h5>Facebook</h5>
          <p>
            Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, a subsidiary of Facebook Inc., 1601 S.
            California Ave., Palo Alto, CA 94304, USA.
          </p>
          <p>
            Privacy Policy:{" "}
            <a href="https://www.facebook.com/policy.php" target="_blank" rel="noreferrer">
              https://www.facebook.com/policy.php
            </a>
          </p>
          <h5>twitter</h5>
          <p>Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA</p>
          <p>
            Privacy Policy:{" "}
            <a href="https://twitter.com/privacy" target="_blank" rel="noreferrer">
              https://twitter.com/privacy
            </a>
          </p>
          <h5>YouTube</h5>
          <p>
            Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, a subsidiary of Google LLC, 1600
            Amphitheatre Parkway, Mountain View, CA 94043 USA
          </p>
          <p>
            Privacy Policy:{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
          </p>
          <h5>LinkedIn</h5>
          <p>
            LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, a subsidiary of LinkedIn
            Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085 USA.
          </p>
          <p>
            Privacy Policy:{" "}
            <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noreferrer">
              https://www.linkedin.com/legal/privacy-policy
            </a>
          </p>
          <h5>Instagram</h5>
          <p>
            Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, a subsidiary of Facebook Inc., 1601 S.
            California Ave., Palo Alto, CA 94304, USA.
          </p>
          <p>
            Privacy Policy:{" "}
            <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noreferrer">
              https://help.instagram.com/519522125107875
            </a>
          </p>
        </div>
      </section>
      {/* 5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? */}
      <section id="doWeUseCookes" className="py-3">
        <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your
          information.
        </p>
        <p className="py-4">
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
          information.
        </p>

        <h4>Cookies</h4>
        <ul className="list-decimal ml-4">
          <li>
            <h5 className="font-medium text-base">Session cookies</h5>
            <p>
              Cookies are small text files or other storage technologies stored on your computer by your browser. These
              cookies process certain specific information about you, such as your browser, location data, or IP
              address. &nbsp;
            </p>
            <p>
              This processing makes our website more user-friendly, efficient, and secure, allowing us, for example, to
              display our website in different languages or to offer a shopping cart function.
            </p>
            <p>
              The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR, insofar as these cookies are used to
              collect data to initiate or process contractual relationships.
            </p>
            <p>
              If the processing does not serve to initiate or process a contract, our legitimate interest lies in
              improving the functionality of our website. The legal basis is then Art. 6 Para. 1 lit. f) GDPR.
            </p>
            <p>When you close your browser, these session cookies are deleted.</p>
          </li>
          <li>
            <h5 className="font-medium text-base">Third-party cookies</h5>
            <p>
              If necessary, our website may also use cookies from companies with whom we cooperate for the purpose of
              advertising, analyzing, or improving the features of our website.
            </p>
            <p>
              Please refer to the following information for details, in particular for the legal basis and purpose of
              such third-party collection and processing of data collected through cookies.
            </p>
          </li>
          <li>
            {/*Affilinet tracking cookies */}
            <h5>Affilinet tracking cookies</h5>
            <p>
              We may also advertise third-party offers and services on our website. If you enter into a contract with
              the third party after viewing our advertising for these third party offers, we will receive a commission
              for this referral.
            </p>
            <p>
              We use the affilinet tracking cookie to record this successful conversion. However, this cookie does not
              store any of your personal data. Only our identification number as the affiliate advertiser and the serial
              number of the advertising material you clicked on (e.g. a banner or a text link) are recorded. We need
              this information for the purpose of processing and/or receiving payment of our commissions.
            </p>
            <p>
              If you do not agree to this processing, you have the option of preventing the installation of cookies by
              making the appropriate settings in your browser. Further details can be found in the section about cookies
              above.
            </p>
          </li>
          <li>
            <h5 className="font-medium text-base">Disabling cookies</h5>
            <p>
              You can refuse the use of cookies by changing the settings on your browser. Likewise, you can use the
              browser to delete cookies that have already been stored. However, the steps and measures required vary,
              depending on the browser you use. If you have any questions, please use the help function or consult the
              documentation for your browser or contact its maker for support. Browser settings cannot prevent so-called
              flash cookies from being set. Instead, you will need to change the setting of your Flash player. The steps
              and measures required for this also depend on the Flash player you are using. If you have any questions,
              please use the help function or consult the documentation for your Flash player or contact its maker for
              support.
            </p>
            <p>
              If you prevent or restrict the installation of cookies, not all of the functions on our site may be fully
              usable.
            </p>
          </li>
        </ul>
      </section>
      {/* 6. HOW LONG DO WE KEEP YOUR INFORMATION? */}
      <section id="howLongDoWeKeepInformation" className="py-3">
        <h2>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> We keep your information for as long as necessary to fulfil the purposes outlined
          in this privacy notice unless otherwise required by law.
        </p>
        <p className="py-4">
          We will only keep your personal information for as long as it is necessary for the purposes set out in this
          privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or
          other legal requirements).
        </p>
        <p className="py-4">
          When we have no ongoing legitimate business need to process your personal information, we will either delete
          or anonymise such information, or, if this is not possible (for example, because your personal information has
          been stored in backup archives), then we will securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
      </section>
      {/* 7. HOW DO WE KEEP YOUR INFORMATION SAFE? */}
      <section id="howDoWeKeepYourInformationSave" className="py-3">
        <h2>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> We aim to protect your personal information through a system of organisational and
          technical security measures.
        </p>
        <p className="py-4">
          <strong>In Short:</strong> We have implemented appropriate and reasonable technical and organisational
          security measures designed to protect the security of any personal information we process. However, despite
          our safeguards and efforts to secure your information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that
          hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and
          improperly collect, access, steal, or modify your information. Althoughwe will do our best to protect your
          personal information, transmission of personal information to and from our Services is at your own risk. You
          should only access the Services within a secure environment.
        </p>
      </section>
      {/* 8. DO WE COLLECT INFORMATION FROM MINORS? */}
      <section id="doWeCollectInformationFromMinors" className="py-3">
        <h2>8. DO WE COLLECT INFORMATION FROM MINORS?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.
        </p>
        <p className="py-4">
          <strong>In Short:</strong> We do not knowingly solicit data from or market to children under 18 years of age.
          By using the Services, you represent that you are at least 18 or that you are the parentor guardian of such a
          minor and consent to such minor dependent`s use of the Services. If we learn that personal information from
          users less than 18 years of age has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become aware of any data we may have collected
          from children under age 18, please contact us at {contactDetails.email}.
        </p>
      </section>
      {/* 9. WHAT ARE YOUR PRIVACY RIGHTS? */}
      <section id="whatAreYourPrivacyRights" className="py-3">
        <h2>9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and
          Canada, you have rights that allow you greater access to and control over your personal information. You may
          review, change, or terminate your account at any time.
        </p>
        <p className="py-4">
          In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws.
          These may include the right (i) to request access and obtain a copy of your personal information, (ii) to
          request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may also have the right to object to the
          processing of your personal information. You can make such a request by contacting us by using the contact
          details provided in the section{" "}
          <a href="#HowCanYouContactUsAboutThisNotice">'HOW CAN YOU CONTAC TUS ABOUT THIS NOTICE?'</a> below.
        </p>
        <p className="py-4">
          We will consider and act upon any request in accordance with applicable data protection laws.
        </p>
        <p className="py-4">
          If you are located in the EEA or UK and you believe we are unlawfully processing your personal information,
          you also have the right to complain to your local data protection supervisory authority. You can find their
          contact details here:{" "}
          <a
            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            target="_blank"
            rel="noreferrer">
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          .
        </p>
        <p className="py-4">
          If you are located in Switzerland, the contact details for the data protection authorities are available here:{" "}
          <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noreferrer">
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </a>
          .
        </p>
        <p className="py-4">
          <strong id="withdrawYourConsent" className="underline">
            Withdrawing your consent:
          </strong>{" "}
          If we are relying on your consent to process your personal information, which may be express and/or implied
          consent depending on the applicable law, you have the right to withdraw your consent at any time. You can
          withdraw your consent at any time by contacting us by using the contact details provided in the section{" "}
          <a href="#HowCanYouContactUsAboutThisNotice" target="_blank" rel="noreferrer">
            'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
          </a>{" "}
          below.
        </p>
        <p className="py-4">
          However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
          when applicable law allows, will it affect the processing of your personal information conducted in reliance
          on lawful processing grounds other than consent.
        </p>
        <p className="py-4">
          <strong className="underline">Cookies and similar technologies:</strong> Most Web browsers are set to accept
          cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject
          cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of
          our Services. To opt out of interest-based advertising by advertisers on our Services visit{" "}
          <a href="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer">
            http://www.aboutads.info/choices/
          </a>
          .
        </p>
        <p className="py-4">
          If you have questions or comments about your privacy rights, you may email us at {contactDetails.email}.
        </p>
      </section>
      {/* 10. CONTROLS FOR DO-NOT-TRACK FEATURES */}
      <section id="controlsForDoNotTrackFeatures" className="py-3">
        <h2>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
        <p className="py-4">
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT')
          feature or setting you can activate to signal your privacy preference not to have data about your online
          browsing activities monitored and collected. At this stage no uniform technology standard for recognising and
          implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or
          any other mechanism that automatically communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will inform you about that practice in a
          revised version of this privacy notice.
        </p>
      </section>
      {/* 11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? */}
      <section id="doClaiforniaResidentsHaveSpecificPrivacyRights" className="py-3">
        <h2>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> Yes, if you are a resident of California, you are granted specific rights regarding
          access to your personal information.
        </p>
        <p className="py-4">
          California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are
          California residents to request and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third parties for direct marketing purposes and
          the names and addresses of all third parties with which we shared personal information in the immediately
          preceding calendar year. If you are a California resident and would like to make such a request, please submit
          your request in writing to us using the contact information provided below.
        </p>
        <p className="py-4">
          If you are under 18 years of age, reside in California, and have a registered account with Services, you have
          the right to request removal of unwanted data that you publicly post on the Services. To request removal of
          such data, please contact us using the contact information provided below and include the email address
          associated with your account and a statement that you reside in California. We will make sure the data is not
          publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>
        <div>
          <h3>CCPA Privacy Notice</h3>
          <div className="py-4">
            The California Code of Regulations defines a 'resident' as:
            <ul className="list-outside ml-8 list-decimal">
              <li className="py-2">
                every individual who is in the State of California for other than a temporary or transitory purpose and
              </li>
              <li className="py-2">
                every individual who is domiciled in the State of California who is outside the State of California for
                a temporary or transitory purpose
              </li>
            </ul>
            <p>All other individuals are defined as 'non-residents'.</p>
            <p>
              If this definition of 'resident' applies to you, we must adhere to certain rights and obligations
              regarding your personal information.
            </p>
          </div>
        </div>
        <div>
          <h4>What categories of personal information do we collect?</h4>
          <div className="py-4">
            We have collected the following categories of personal information in the past twelve (12) months:
            <table className="my-4 table-auto">
              <thead>
                <tr>
                  <th className="p-2 text-left">Category</th>
                  <th className="p-2 text-left">Examples</th>
                  <th className="p-2 text-left">Collected</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">A. Identifiers</td>
                  <td className="p-2">
                    Contact details, such as real name, alias, postal address, telephone or mobile contact number,
                    unique personal identifier, online identifier, Internet Protocol address, email address, and account
                    name
                  </td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">
                    B. Personal information categories listed in the California Customer Records statute
                  </td>
                  <td className="p-2">
                    Name, contact information, education, employment, employment history, and financial information
                  </td>
                  <td className="text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">C. Protected classification characteristics under California or federal law</td>
                  <td className="p-2">Gender and date of birth</td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">D. Commercial information</td>
                  <td className="p-2">
                    Transaction information, purchase history, financial details, and payment information
                  </td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">E. Biometric information</td>
                  <td className="p-2">Fingerprints and voiceprints</td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">F. Internet or other similar network activity</td>
                  <td className="p-2">
                    Browsing history, search history, online behaviour, interest data, and interactions with our and
                    other websites, applications, systems, and advertisements
                  </td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">G. Geolocation data</td>
                  <td className="p-2">Device location</td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                  <td className="p-2">
                    Images and audio, video or call recordings created in connection with our business activities
                  </td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">I. Professional or employment-related information</td>
                  <td className="p-2">
                    Business contact details in order to provide you our Services at a business level or job title, work
                    history, and professional qualifications if you apply for a job with us
                  </td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">J. Education Information</td>
                  <td className="p-2">Student records and directory information</td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">K. Inferences drawn from other personal information</td>
                  <td className="p-2">
                    Inferences drawn from any of the collected personal information listed above to create a profile or
                    summary about, for example, an individual’s preferences and characteristics
                  </td>
                  <td className="p-2 text-center">NO</td>
                </tr>
                <tr>
                  <td className="p-2">L. Sensitive Personal Information</td>
                  <td className="p-2"></td>
                  <td className="p-2 text-center">NO</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="py-4">
            We will use and retain the collected personal information as needed to provide the Services or for:
            <ul className="list-outside ml-8 list-disc">
              <li className="py-2">Category G - As long as the user is on the website</li>
            </ul>
          </div>
          <div className="py-4">
            We may also collect other personal information outside of these categories through instances where you
            interact with us in person, online, or by phone or mail in the context of:
            <ul className="list-outside ml-8 list-disc">
              <li className="py-2">Receiving help through our customer support channels;</li>
              <li className="py-2">Participation in customer surveys or contests; and</li>
              <li className="py-2">Facilitation in the delivery of our Services and to respond to your inquiries.</li>
            </ul>
          </div>
        </div>
        <div>
          <h4>How do we use and share your personal information?</h4>
          <div className="py-4">
            <a href={contactDetails.website.url}>{contactDetails.website.name}</a> collects and shares your personal
            information through:
            <ul className="list-outside ml-8 list-disc">
              <li className="py-2">Social media cookies</li>
              <li className="py-2">Click redirects: YouTube embedded videos.</li>
              <li className="py-2">
                Social media plugins: YouTube subscribe button. We use social media features, such as a 'Like' button,
                and widgets, such as a 'Share' button, in our Services. Such features may process your Internet Protocol
                (IP) address and track which page you are visiting on our website. We may place a cookie to enable the
                feature to work correctly. If you are logged in on a certain social media platform and you interact with
                a widget or button belonging to that social media platform, this information may be recorded to your
                profile of such social media platform. To avoid this, you should log out from that social media platform
                before accessing or using the Services. Social media features and widgets may be hosted by a third party
                or hosted directly on our Services. Your interactions with these features are governed by the privacy
                notices of the companies that provide them. By clicking on one of these buttons, you agree to the use of
                this plugin and consequently the transfer of personal information to the corresponding social media
                service. We have no control over the essence and extent of these transmitted data or their additional
                processing.
              </li>
            </ul>
          </div>
          <div className="py-4">
            More information about our data collection and sharing practices can be found in this privacy notice .
          </div>
          <div className="py-4">
            You may contact us by email at {contactDetails.email} , by visiting{" "}
            <NavLink to={ContactRoute.path} exact={ContactRoute.exact}>
              https://flognity.com/Contact
            </NavLink>{" "}
            , or by referring to the contact details at the bottom of this document.
          </div>
          <div className="py-4">
            If you are using an authorised agent to exercise your right to opt out we may deny a request if the
            authorised agent does not submit proof that they have been validly authorised to act on your behalf.
          </div>
          <h4>Will your information be shared with anyone else?</h4>
          <div className="py-4">
            We may disclose your personal information with our service providers pursuant to a written contract between
            us and each service provider. Each service provider is a for-profit entity that processes the information on
            our behalf, following the same strict privacy protection obligations mandated by the CCPA.
          </div>
          <div className="py-4">
            We may use your personal information for our own business purposes, such as for undertaking internal
            research for technological development and demonstration. This is not considered to be 'selling' of your
            personal information.
          </div>
          <div className="py-4">
            <a href={contactDetails.website.url}>{contactDetails.website.name}</a> has not sold or shared any personal
            information to third parties for a business or commercial purpose in the preceding twelve (12) months.
            <a href={contactDetails.website.url}>{contactDetails.website.name}</a> has disclosed the following
            categories of personal information to third parties for a business or commercial purpose in the preceding
            twelve (12) months:
            <ul className="list-outside ml-8 list-disc">
              <li className="py-2">Category G. Geolocation data, such as device location.</li>
            </ul>
          </div>
          <div className="py-4">
            The categories of third parties to whom we disclosed personal information for a business or commercial
            purpose can be found under{" "}
            <a href="#whenAndWithWhomDoWeShareInformation">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
            .
          </div>
        </div>
        <div>
          <h4>Your rights with respect to your personal data</h4>
          <div>
            <div className="py-4 underline bold">Right to request deletion of the data — Request to delete</div>
            <div className="py-4">
              You can ask for the deletion of your personal information. If you ask us to delete your personal
              information, we will respect your request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her
              right to free speech, our compliance requirements resulting from a legal obligation, or any processing
              that may be required to protect against illegal activities.
            </div>
          </div>
          <div>
            <div className="py-4 underline bold">Right to be informed — Request to know</div>
            <div className="py-4">
              Depending on the circumstances, you have a right to know:
              <ul className="list-outside ml-8 list-disc">
                <li className="py-2">whether we collect and use your personal information;</li>
                <li className="py-2">the categories of personal information that we collect;</li>
                <li className="py-2">the purposes for which the collected personal information is used;</li>
                <li className="py-2">whether we sell or share personal information to third parties;</li>
                <li className="py-2">
                  the categories of personal information that we sold, shared, or disclosed for a business purpose;
                </li>
                <li className="py-2">
                  the categories of third parties to whom the personal information was sold, shared, or disclosed for a
                  business purpose;
                </li>
                <li className="py-2">
                  the business or commercial purpose for collecting, selling, or sharing personal information; and
                </li>
                <li className="py-2">the specific pieces of personal information we collected about you.</li>
              </ul>
              <div className="py-4">
                In accordance with applicable law, we are not obligated to provide or delete consumer information that
                is de-identified in response to a consumer request or to re-identify individual data to verify a
                consumer request.
              </div>
            </div>
          </div>
          <div className="py-4 underline bold">
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
          </div>
          <div className="py-4">We will not discriminate against you if you exercise your privacy rights.</div>
          <div className="py-4 underline bold">Right to Limit Use and Disclosure of Sensitive Personal Information</div>
          <div className="py-4">We do not process consumer's sensitive personal information.</div>
          <div>
            <div className="py-4 underline bold">Verification process</div>
            <div className="py-4">
              Upon receiving your request, we will need to verify your identity to determine you are the same person
              about whom we have the information in our system. These verification efforts require us to ask you to
              provide information so that we can match it with information you have previously provided us. For
              instance, depending on the type of request you submit, we may ask you to provide certain information so
              that we can match the information you provide with the information we already have on file, or we may
              contact you through a communication method (e.g. phone or email) that you have previously provided to us.
              We may also use other verification methods as the circumstances dictate.
            </div>
            <div className="py-4">
              We will only use personal information provided in your request to verify your identity or authority to
              make the request. To the extent possible, we will avoid requesting additional information from you for the
              purposes of verification. However, if we cannot verify your identity from the information already
              maintained by us, we may request that you provide additional information for the purposes of verifying your
              identity and for security or fraud-prevention purposes. We will delete such additionally provided
              information as soon as we finish verifying you.
            </div>
          </div>
          <div>
            <div className="py-4 underline bold">Other privacy rights</div>
            <ul className="list-outside ml-8 list-disc">
              <li className="py-2">You may object to the processing of your personal information.</li>
              <li className="py-2">
                You may request correction of your personal data if it is incorrect or no longer relevant, or ask to
                restrict the processing of the information.
              </li>
              <li className="py-2">
                You can designate an authorised agent to make a request under the CCPA on your behalf. We may deny a
                request from an authorised agent that does not submit proof that they have been validly authorised to
                act on your behalf in accordance with the CCPA.
              </li>
              <li className="py-2">
                You may request to opt out from future selling or sharing of your personal information to third parties.
                Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no
                later than fifteen (15) days from the date of the request submission.
              </li>
            </ul>
            <div className="py-4">
              To exercise these rights, you can contact us by email at {contactDetails.email}, by visiting{" "}
              <NavLink to={ContactRoute.path} exact={ContactRoute.exact}>
                https://flognity.com/Contact
              </NavLink>
              , or by referring to the contact details at the bottom of this document. If you have a complaint about how
              we handle your data, we would like to hear from you.
            </div>
          </div>
        </div>
      </section>
      {/* 12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? */}
      <section id="doVirginiaResidentsHaveSpecificPrivacyRights" className="py-3">
        <h2>12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> Yes, if you are a resident of Virginia, you may be granted specific rights
          regarding access to and use of your personal information.
        </p>
        <h3>Virginia CDPA Privacy Notice</h3>
        <p className="py-4">Under the Virginia Consumer Data Protection Act (CDPA):</p>
        <p className="py-4">
          'Consumer' means a natural person who is a resident of the Commonwealth acting only in an individual or
          household context. It does not include a natural person acting in a commercial or employment context.
        </p>
        <p className="py-4">
          'Personal data' means any information that is linked or reasonably linkable to an identified or identifiable
          natural person. 'Personal data' does not include de-identified data or publicly available information.
        </p>
        <p className="py-4">'Sale of personal data' means the exchange of personal data for monetary consideration.</p>
        <p className="py-4">
          If this definition 'consumer' applies to you, we must adhere to certain rights and obligations regarding your
          personal data.
        </p>
        <div className="py-4">
          The information we collect, use, and disclose about you will vary depending on how you interact with{" "}
          <a href={contactDetails.website.url}>{contactDetails.website.name}</a> and our Services. To find out more,
          please visit the following links:
          <ul className="list-outside ml-8 list-disc">
            <li className="py-2">
              <a href="#personalInformation">Personal data we collect</a>
            </li>
            <li className="py-2">
              <a href="#whatDoWeProcess">How we use your personal data</a>
            </li>
            <li className="py-2">
              <a href="#whenAndWithWhomDoWeShareInformation">When and with whom we share your personal data</a>
            </li>
          </ul>
        </div>
        <div>
          <div className="py-4 underline bold">Your rights with respect to your personal data</div>
          <ul className="list-outside ml-8 list-disc">
            <li className="py-2">Right to be informed whether or not we are processing your personal data</li>
            <li className="py-2">Right to access your personal data</li>
            <li className="py-2">Right to correct inaccuracies in your personal data</li>
            <li className="py-2">Right to request deletion of your personal data</li>
            <li className="py-2">Right to obtain a copy of the personal data you previously shared with us</li>
            <li className="py-2">
              Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale
              of personal data, or profiling in furtherance of decisions that produce legal or similarly significant
              effects ('profiling')
            </li>
          </ul>
          <div className="py-4">
            <a href={contactDetails.website.url}>{contactDetails.website.name}</a> has not sold any personal data to
            third parties for business or commercial purposes.{" "}
            <a href={contactDetails.website.url}>{contactDetails.website.name}</a> will not sell personal data in the
            future belonging to website visitors, users, and other consumers.
          </div>
        </div>
        <div>
          <div className="py-4 underline bold">Exercise your rights provided under the Virginia CDPA</div>
          <div className="py-4">
            More information about our data collection and sharing practices can be found in this privacy notice.
          </div>
          <div className="py-4">
            You may contact us by email at {contactDetails.email} or by referring to the contact details at the bottom
            of this document.
          </div>
          <div className="py-4">
            If you are using an authorised agent to exercise your rights, we may deny a request if the authorised agent
            does not submit proof that they have been validly authorised to act on your behalf.
          </div>
        </div>
        <div>
          <div className="py-4 underline bold">Verification process</div>
          <div className="py-4">
            We may request that you provide additional information reasonably necessary to verify you and your
            consumer's request. If you submit the request through an authorised agent, we may need to collect additional
            information to verify your identity before processing your request.
          </div>
          <div className="py-4">
            Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45)
            days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably
            necessary. We will inform you of any such extension within the initial 45-day response period, together with
            the reason for the extension.
          </div>
        </div>
        <div>
          <div className="py-4 underline bold">Right to appeal</div>
          <div className="py-4">
            If we decline to take action regarding your request, we will inform you of our decision and reasoning behind
            it. If you wish to appeal our decision, please email us at {contactDetails.email}. Within sixty (60) days of
            receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the
            appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may
            contact the Attorney General to submit a complaint.
          </div>
        </div>
      </section>
      {/* 13. DO WE MAKE UPDATES TO THIS NOTICE? */}
      <section id="doWeMakeUpdatesToThisNotice" className="py-3">
        <h2>13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
        <p className="italic py-4">
          <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
        </p>
        <p className="py-4">
          We may update this privacy notice from time to time. The updated version will be indicated by an updated
          'Revised' date and the updated version will be effective as soon as it is accessible. If we make material
          changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
          directly sending you a notification. We encourage you to review this privacy notice frequently to be informed
          of how we are protecting your information.
        </p>
      </section>
      {/* 14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? */}
      <section id="HowCanYouContactUsAboutThisNotice" className="py-3">
        <h2>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
        <div className="py-4">
          If you have questions or comments about this notice, you may email us at {contactDetails.email} or by post to:
          <div className="py-2">
            <CurrentAddressBr />
          </div>
        </div>
      </section>
      {/* 15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? */}
      <section id="howCanYouReviewUpdateDeleteCollectedData" className="py-3">
        <h2>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
        <p className="py-4">
          Based on the applicable laws of your country, you may have the right to request access to the personal
          information we collect from you, change that information, or delete it. To request to review, update, or
          delete your personal information, please submit a request form by clicking{" "}
          <NavLink to={ContactRoute.path} exact={ContactRoute.exact}>
            here
          </NavLink>
          .
        </p>
      </section>

      <section id="presenceOnOtherPlatforms" className="py-3">
        <h2>16. PRESENCE ON OTHER PLATFORMS, SERVICES AND WEBSITES</h2>
        {/* Twitter presence */}
        <div>
          <h4>Twitter</h4>
          <p>
            We maintain an online presence on Twitter to present our company and our services and to communicate with
            customers/prospects. Twitter is a service provided by Twitter Inc., 1355 Market Street, Suite 900, San
            Francisco, CA 94103, USA.
          </p>
          <p>
            We would like to point out that this might cause user data to be processed outside the European Union,
            particularly in the United States. This may increase risks for users that, for example, may make subsequent
            access to the user data more difficult. We also do not have access to this user data. Access is only
            available to Twitter.
          </p>
          <p>The privacy policy of Twitter can be found at</p>
          <p>
            <a href="https://twitter.com/de/privacy" target="_blank" rel="noreferrer">
              https://twitter.com/privacy
            </a>
          </p>
        </div>
        {/* YouTube presence */}
        <div>
          <h4>YouTube</h4>
          <p>
            We maintain an online presence on YouTube to present our company and our services and to communicate with
            customers/prospects. YouTube is a service of Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Ireland, a subsidiary of Google LLC, 1600 Amphitheater Parkway, Mountain View, CA 94043 USA.
          </p>
          <p>
            We would like to point out that this might cause user data to be processed outside the European Union,
            particularly in the United States. This may increase risks for users that, for example, may make subsequent
            access to the user data more difficult. We also do not have access to this user data. Access is only
            available to YouTube.
          </p>
          <p>The YouTube privacy policy can be found here:</p>
          <p>
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
          </p>
        </div>
        {/* LinkedIn presence */}
        <div>
          <h4>LinkedIn</h4>
          <p>
            We maintain an online presence on LinkedIn to present our company and our services and to communicate with
            customers/prospects. LinkedIn is a service of LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton
            Place, Dublin 2, Irland, a subsidiary of LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085,
            USA.
          </p>
          <p>
            We would like to point out that this might cause user data to be processed outside the European Union,
            particularly in the United States. This may increase risks for users that, for example, may make subsequent
            access to the user data more difficult. We also do not have access to this user data. Access is only
            available to LinkedIn.
          </p>
          <p>The LinkedIn privacy policy can be found here:</p>
          <p>
            <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noreferrer">
              https://www.linkedin.com/legal/privacy-policy
            </a>
          </p>
        </div>
        {/* Facebook presence */}
        <div>
          <h4>Facebook</h4>
          <p>
            To advertise our products and services as well as to communicate with interested parties or customers, we
            have a presence on the Facebook platform.
          </p>
          <p>
            On this social media platform, we are jointly responsible with Facebook Ireland Ltd., 4 Grand Canal Square,
            Grand Canal Harbor, Dublin 2, Ireland.
          </p>
          <p>The data protection officer of Facebook can be reached via this contact form:</p>
          <p>
            <a href="https://www.facebook.com/help/contact/540977946302970" target="_blank" rel="noreferrer">
              https://www.facebook.com/help/contact/540977946302970
            </a>
          </p>
          <p>
            We have defined the joint responsibility in an agreement regarding the respective obligations within the
            meaning of the GDPR. This agreement, which sets out the reciprocal obligations, is available at the
            following link:
          </p>
          <p>
            <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noreferrer">
              https://www.facebook.com/legal/terms/page_controller_addendum
            </a>
          </p>
          <p>
            The legal basis for the processing of the resulting and subsequently disclosed personal data is Art. 6 para.
            1 lit. f GDPR. Our legitimate interest lies in the analysis, communication, sales, and promotion of our
            products and services.
          </p>
          <p>
            The legal basis may also be your consent per Art. 6 para. 1 lit. a GDPR granted to the platform operator.
            Per Art. 7 para. 3 GDPR, you may revoke this consent with the platform operator at any time with future
            effect.
          </p>
          <p>
            When accessing our online presence on the Facebook platform, Facebook Ireland Ltd. as the operator of the
            platform in the EU will process your data (e.g. personal information, IP address, etc.).
          </p>
          <p>
            This data of the user is used for statistical information on the use of our company presence on Facebook.
            Facebook Ireland Ltd. uses this data for market research and advertising purposes as well as for the
            creation of user profiles. Based on these profiles, Facebook Ireland Ltd. can provide advertising both
            within and outside of Facebook based on your interests. If you are logged into Facebook at the time you
            access our site, Facebook Ireland Ltd. will also link this data to your user account.
          </p>
          <p>
            If you contact us via Facebook, the personal data you provide at that time will be used to process the
            request. We will delete this data once we have completely responded to your query, unless there are legal
            obligations to retain the data, such as for subsequent fulfillment of contracts.
          </p>
          <p>Facebook Ireland Ltd. might also set cookies when processing your data.</p>
          <p>
            If you do not agree to this processing, you have the option of preventing the installation of cookies by
            making the appropriate settings in your browser. Cookies that have already been saved can be deleted at any
            time. The instructions to do this depend on the browser and system being used. For Flash cookies, the
            processing cannot be prevented by the settings in your browser, but instead by making the appropriate
            settings in your Flash player. If you prevent or restrict the installation of cookies, not all of the
            functions of Facebook may be fully usable.
          </p>
          <p>
            Details on the processing activities, their suppression, and the deletion of the data processed by Facebook
            can be found in its privacy policy:
          </p>
          <p>
            <a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noreferrer">
              https://www.facebook.com/privacy/explanation
            </a>
          </p>
          <p>
            It cannot be excluded that the processing by Facebook Ireland Ltd. will also take place in the United States
            by Facebook Inc., 1601 Willow Road, Menlo Park, California 94025.
          </p>
        </div>
        {/* Instagram presence */}
        <div>
          <h4>Instagram</h4>
          <p>
            To advertise our products and services as well as to communicate with interested parties or customers, we
            have a presence on the Instagram platform.
          </p>
          <p>
            On this social media platform, we are jointly responsible with Facebook Ireland Ltd., 4 Grand Canal Square,
            Grand Canal Harbour, Dublin 2 Ireland.
          </p>
          <p>The data protection officer of Instagram can be reached via this contact form:</p>
          <p>
            <a href="https://www.facebook.com/help/contact/540977946302970" target="_blank" rel="noreferrer">
              https://www.facebook.com/help/contact/540977946302970
            </a>
          </p>
          <p>
            We have defined the joint responsibility in an agreement regarding the respective obligations within the
            meaning of the GDPR. This agreement, which sets out the reciprocal obligations, is available at the
            following link:
          </p>
          <p>
            <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noreferrer">
              https://www.facebook.com/legal/terms/page_controller_addendum
            </a>
          </p>
          <p>
            The legal basis for the processing of the resulting and subsequently disclosed personal data is Art. 6 para.
            1 lit. f GDPR. Our legitimate interest lies in the analysis, communication, sales, and promotion of our
            products and services.
          </p>
          <p>
            The legal basis may also be your consent per Art. 6 para. 1 lit. a GDPR granted to the platform operator.
            Per Art. 7 para. 3 GDPR, you may revoke this consent with the platform operator at any time with future
            effect.
          </p>
          <p>
            When accessing our online presence on the Instagram platform, Facebook Ireland Ltd. as the operator of the
            platform in the EU will process your data (e.g. personal information, IP address, etc.).
          </p>
          <p>
            This data of the user is used for statistical information on the use of our company presence on Instagram.
            Facebook Ireland Ltd. uses this data for market research and advertising purposes as well as for the
            creation of user profiles. Based on these profiles, Facebook Ireland Ltd. can provide advertising both
            within and outside of Instagram based on your interests. If you are logged into Instagram at the time you
            access our site, Facebook Ireland Ltd. will also link this data to your user account.
          </p>
          <p>
            If you contact us via Instagram, the personal data you provide at that time will be used to process the
            request. We will delete this data once we have completely responded to your query, unless there are legal
            obligations to retain the data, such as for subsequent fulfillment of contracts.
          </p>
          <p>Facebook Ireland Ltd. might also set cookies when processing your data.</p>
          <p>
            If you do not agree to this processing, you have the option of preventing the installation of cookies by
            making the appropriate settings in your browser. Cookies that have already been saved can be deleted at any
            time. The instructions to do this depend on the browser and system being used. For Flash cookies, the
            processing cannot be prevented by the settings in your browser, but instead by making the appropriate
            settings in your Flash player. If you prevent or restrict the installation of cookies, not all of the
            functions of Instagram may be fully usable.
          </p>
          <p>
            Details on the processing activities, their suppression, and the deletion of the data processed by Instagram
            can be found in its privacy policy:
          </p>
          <p>
            <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noreferrer">
              https://help.instagram.com/519522125107875
            </a>
          </p>
          <p>
            It cannot be excluded that the processing by Facebook Ireland Ltd. will also take place in the United States
            by Facebook Inc., 1601 Willow Road, Menlo Park, California 94025.
          </p>
        </div>
      </section>
      {/* Reference */}
      <section className="py-3 pt-10">
        <div>
          This Data Protection Statement is based on the{" "}
          <a href="https://termly.io/products/privacy-policy-generator/" target="_blank" rel="noreferrer">
            Privacy Policy Generator
          </a>{" "}
          by{" "}
          <a href="https://termly.io/" target="_blank" rel="noreferrer">
            Termly
          </a>{" "}
          and the{" "}
          <a
            href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
            target="_blank"
            rel="noreferrer">
            Model Data Protection Statement
          </a>{" "}
          provided by{" "}
          <a href="https://www.ratgeberrecht.eu/" target="_blank" rel="noreferrer">
            Anwaltskanzlei Weiß &amp; Partner
          </a>
          .
        </div>
      </section>
    </div>
  );
}

export default PrivacyStatement;
