//Imports for npm packages
import IconTwitter from 'super-tiny-icons/images/svg/twitter.svg';
import IconFacebook from 'super-tiny-icons/images/svg/facebook.svg';
import IconInstagram from 'super-tiny-icons/images/svg/instagram.svg';
import IconLinkedIn from 'super-tiny-icons/images/svg/linkedin.svg';
import IconGitHub from 'super-tiny-icons/images/svg/github.svg';
//Imports for components
import Home from './home/Home';
import Youtube from './/youtube/Youtube';
import LegalDisclosure from './legal/LegalDisclosure';
import PrivacyStatement from './legal/PrivacyStatement';
import SoftwareDeveloper from './aboutme/AboutMe';
import Philanthropy from './philanthropy/Philanthropy';
import Bucketlist from './bucketlist/Bucketlist';
import Contact from './contact/Contact';

interface Iroutes {
  path: string,
  exact: boolean, //True if route needs to be spelled exactly. Necessary for the Home route "/"
  text: string,
  component: undefined | (() => JSX.Element);
  routes?: Iroutes[]
}

export const routesLegalLinks: Record<string, Iroutes> = {
  LegalDisclosure: {
    path: "/LegalDisclosure",
    exact: false,
    text: "Legal Disclosure",
    component: LegalDisclosure
  },
  PrivacyStatement: {
    path: "/PrivacyStatement",
    exact: false,
    text: "Privacy Statement",
    component: PrivacyStatement
  },
};

export const routesSiteMap: Record<string, Iroutes> = {
  Home: {
    path: "/",
    exact: true,
    text: "Welcome",
    component: Home
  },
  AboutMe: {
    path: "/AboutMe",
    exact: false,
    text: "About Me",
    component: SoftwareDeveloper
  },
  YouTube: {
    path: "/YouTube",
    exact: false,
    text: "YouTube",
    component: Youtube
  },
  Philanthropy: {
    path: "/EnvironmentalPhilanthropy",
    exact: false,
    text: "Philanthropy",
    component: Philanthropy
  },
  Bucketlist: {
    path: "/Bucketlist",
    exact: false,
    text: "Bucket List",
    component: Bucketlist
  },
  Contact: {
    path: "/Contact",
    exact: false,
    text: "Contact",
    component: Contact
  },
};

interface ILinks {
  href: string,
  icon: string,
  text: string
}

export const linksSocialMedia: Record<string, ILinks> = {
  GitHubFlognity: {
    href: "https://github.com/flognity",
    icon: IconGitHub,
    text: "GitHub"
  },
  LinkedInFlorianWilhelm: {
    href: "https://www.linkedin.com/in/florian-wilhelm/",
    icon: IconLinkedIn,
    text: "LinkedIn"
  },
  InstagramFlognity: {
    href: "https://www.instagram.com/flognity/",
    icon: IconInstagram,
    text: "Instagram"
  },
  FacebookFlorianWilhelm: {
    href: "https://www.facebook.com/wilhelm.florian/",
    icon: IconFacebook,
    text: "Facebook"
  },
  TwitterFlognity: {
    href: "https://twitter.com/flognity",
    icon: IconTwitter,
    text: "Twitter"
  },

};

