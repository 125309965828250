//Imports for npm packages
import { useEffect, useState } from 'react';

const useScript = (src: string) => {
    // Keep track of script status ("idle", "loading", "ready", "error")
    const [status, setStatus] = useState(src ? "loading" : "idle");

    useEffect(() => {
        if (!src) {
            setStatus("idle");
            return;
        }

        //only create the script element if it doesn't exist yet
        let script: HTMLScriptElement;
        const scriptfetch: HTMLScriptElement | null = document.body.querySelector(`script[src="${src}"]`);
        if (!scriptfetch) {
            script = document.createElement("script");
            script.src = src;
            script.async = true;
            script.setAttribute("data-status", "loading");
            document.body.appendChild(script);

            // Store status in attribute on script
            const setAttributeFromEvent = (event: Event) => {
                script.setAttribute(
                "data-status",
                event.type === "load" ? "ready" : "error"
                );
            };

            script.addEventListener("load", setAttributeFromEvent);
            script.addEventListener("error", setAttributeFromEvent);
        } else {
            // Grab existing script status from attribute and set to state.
            script = scriptfetch;
            setStatus(script.getAttribute("data-status") ?? "error");
        }

        // Script event handler to update status in state
        const setStateFromEvent = (event: Event) => {
            setStatus(event.type === "load" ? "ready" : "error");
        };

        // Add event listeners
        script.addEventListener("load", setStateFromEvent);
        script.addEventListener("error", setStateFromEvent);

        // Specify how to clean up after this effect:
        return () => {
            if (script) {
                script.removeEventListener("load", setStateFromEvent);
                script.removeEventListener("error", setStateFromEvent);
              }
        }
    }, [src]); // Only re-run effect if script src changes

    return status;
};

export default useScript;