import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Starfield.css";

interface IStarfieldProps {
  className: string;
  minVelocity?: number;
  maxVelocity?: number;
  stars?: number;
  layers?: number;
}

interface IStar {
  x: number;
  y: number;
  size: number;
  velocity: number;
  opacity: number;
}

export default function Starfield(props: React.PropsWithChildren<IStarfieldProps>) {
  const minVelocity = props.minVelocity !== undefined && props.minVelocity > 0 ? props.minVelocity : 20;
  const maxVelocity = props.maxVelocity !== undefined && props.maxVelocity > 0 ? props.maxVelocity : 120;
  const layers = props.layers !== undefined && props.layers > 0 ? props.layers : 5;
  const stars = props.stars !== undefined && props.stars > 0 ? props.stars : 150;
  const starsPerLayer = Math.floor(stars / layers);

  const refOuterDiv = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const [starsArray, setStarsArray] = useState<IStar[][]>([]);

  useLayoutEffect(() => {
    function updateWindowDimensions() {
      setWidth(refOuterDiv.current ? refOuterDiv.current.offsetWidth : 0);
      setHeight(refOuterDiv.current ? refOuterDiv.current.offsetHeight : 0);
    }

    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    const generateStars = (): IStar[][] => {
      return Array.from({ length: layers }, () => {
        const layerVelocity = Math.random() * (maxVelocity - minVelocity) + minVelocity;
        return Array.from({ length: starsPerLayer }, () => ({
          x: Math.random() * 100,
          y: Math.random() * 100,
          size: Math.random() * 3 + 1,
          velocity: layerVelocity,
          opacity: Math.random(),
        }));
      });
    };
    setStarsArray(generateStars());
  }, [width, height, layers, starsPerLayer, minVelocity, maxVelocity]);

  return (
    <div ref={refOuterDiv} className={`relative ${props.className}`}>
      {props.children}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden" style={{ zIndex: -1 }}>
        {starsArray.map((starArray, indexLayer) => {
          const layerVelocity = starArray[0]?.velocity ?? minVelocity;
          return (
            <div
              key={`starLayer_${indexLayer}`}
              className="absolute bg-transparent star-layer"
              style={{
                width: "200%",
                height: "100%",
                left: "0%",
                animation: `translateX-stars ${layerVelocity}s linear infinite`,
              }}
            >
              {["0%", "50%"].map((leftPos, repIndex) => (
                <div
                  key={`starLayer_${indexLayer}_rep${repIndex}`}
                  className="absolute bg-transparent"
                  style={{
                    zIndex: -1,
                    width: "50%",
                    height: "100%",
                    left: leftPos,
                    top: "0%",
                  }}
                >
                  {starArray.map((star, index) => (
                    <div
                      key={`star_${index}`}
                      className="absolute block bg-white rounded-full"
                      style={{
                        left: `${star.x}%`,
                        top: `${star.y}%`,
                        width: `${star.size}px`,
                        height: `${star.size}px`,
                        opacity: star.opacity,
                      }}
                    ></div>
                  ))}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
