//Imports for npm packages
import React, { useEffect, useState } from "react";
//Imports for components
import YouTubeSubscribeButton from "../../components/YouTubeApi/YouTubeSubscribeButton";
import YouTubePlayer from "../../components/YouTubeApi/YouTubePlayer";

const youTubeChannels: {
  [channelName: string]: {
    channelName: string;
    href: string;
    channelid: string;
    imgSrc: string;
    imgClass: string;
  };
} = {
  flognity: {
    channelName: "flognity",
    href: "#YouTubeFlognity",
    channelid: "UCrKydXIhDJ5npnGMDQcWpZg",
    imgSrc: "/img/LogoYTflognity.jpg",
    imgClass: "rounded-full",
  },
  flognityGaming: {
    channelName: "flognity gaming",
    href: "#YouTubeFlognityGaming",
    channelid: "UC5UfTajJz4if5nQzf6zhUIA",
    imgSrc: "/img/flognityLogo.png",
    imgClass: "",
  },
  // flognityDE: {
  //   channelName: "flognity DE",
  //   href: "#YouTubeFlognityDe",
  //   channelid: "UCDECFfwKH3Z2q8kkG_A6rJQ",
  //   imgSrc: "/img/LogoYTflognityDe.jpg",
  //   imgClass: "rounded-full",
  // },
};

function YouTube() {
  const [hoveredChannel, setHoveredChannel] = useState("");

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    //set the size of the window
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    //scroll to the YouTube selector portion of the page
    if (windowDimensions.width < 640) {
      document.getElementById("YouTubeSelector")?.scrollIntoView();
    }
    //clean up
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions.width]);

  return (
    <div>
      {/* YouTube selector */}
      <section
        id="YouTubeSelector"
        className="h-screen sm:h-auto flex flex-col sm:flex-row bg-blueGray-800 bg-opacity-80 shadow-xl">
        {Object.values(youTubeChannels).map((channel, index) => {
          return (
            <a
              key={`YouTubeSlider_${index}`}
              href={channel.href}
              className={`text-white transition-height duration-500 ease-in 
              sm:transition-width sm:duration-500 sm:ease-in 
              border-solid border-warmGray-20 ${
                hoveredChannel === channel.channelid
                  ? "h-3/4 sm:w-3/4"
                  : `h-1/2 sm:h-auto sm:w-1/2`
              }`}
              onMouseEnter={() => setHoveredChannel(channel.channelid)}
              onMouseLeave={() => {
                if (hoveredChannel === channel.channelid) {
                  setHoveredChannel("");
                }
              }}>
              <div
                className={`flex flex-col justify-center items-center h-full sm:h-96 ${
                  index > 0 ? "border-t-4 sm:border-t-0 sm:border-l-2" : ""
                }`}>
                <h1 className="py-6">{channel.channelName}</h1>
                <img className={`h-32 mb-4 ${channel.imgClass}`} src={channel.imgSrc} alt="flognity" />
              </div>
            </a>
          );
        })}
      </section>

      <div className="px-general mx-auto w-full xl:max-w-4xl 2xl:max-w-7xl">
        {/* flognity */}
        <section id="YouTubeFlognity" className="py-6">
          <div className="text-center my-6 mx-6 md:mx-16 xl:mx-40 flex flex-wrap justify-center items-center">
            <h1 className="text-5xl md:text-6xl xl:text-7xl md:whitespace-nowrap pr-4">flognity</h1>
            <div className="mb-4 mt-10">
              <YouTubeSubscribeButton channelid="UCrKydXIhDJ5npnGMDQcWpZg" />
            </div>
          </div>

          <YouTubePlayer
            key="flognity_YouTube"
            id="flognity_YouTube"
            videoId="spe-My_WhnQ"
            containerWrapperClassName="float-left mr-8 mb-4"
            containerClassName="relative pt-0 h-0 overflow-hidden"
            className="absolute top-0 left-0 w-full h-full"
            opts={{ height: 315, width: 560 }}
          />

          <div>
            <h1>Welcome to the world of flognity.</h1>
            <div>
              <div>
                I wasn’t happy in life, so I made the bold move and quit my secure office job in Germany to finally
                immigrate to Canada.
              </div>
              <div className="py-4">
                I decided to live life to its fullest, check off all my bucket list items and find purpose in life. My
                impact should make a difference.
              </div>
              <div>
                I’m an action lover, theme park freak, thrill-seeker, traveller, animal lover, environmentalist and
                scientist. I like to seek comfort in discomfort and excel in challenges. Whether it’s environmental
                work, wildlife rehabilitation, volunteering positions or other purposeful activities: join me on my
                journey to find happiness. If I can do it, so can you! Together, we can make a difference.
              </div>
            </div>

            <div className="clear-left">
              <a href="https://www.youtube.com/channel/UCrKydXIhDJ5npnGMDQcWpZg">
                <strong>This chanel</strong>
              </a>{" "}
              is about:
              <ul className="font-bold list-outside pt-2 ml-8 list-disc">
                <li>financial independence,</li>
                <li>self-improvement,</li>
                <li>bucket list items,</li>
                <li>finding purpose in life,</li>
                <li>reaching life goals,</li>
                <li>lifestyle choice</li>
                <li>and how to make a difference</li>
              </ul>
            </div>
          </div>

          <div className="clear-left">
            {/* Q&A */}
            <h2>{"Q&A"}</h2>
            <h3>What does flognity stand for?</h3>
            <div>My first YouTube Cahnnel (now called flognity gaming) was about gaming and game summaries.</div>
            <div>
              Since most people call me Flo the acronym flognity made sense for my channel name. flognity stands for "
              <b>Flo</b>rian's <b>g</b>aming commu
              <b>nity</b>".
            </div>
            <h3>Why do you sometimes take so long to upload another video?</h3>
            <div>Video editing in general takes a lot of effort and time.</div>
            <div>Futhermore, my journey is all about self-improvement, making progress, exploring new hobbies and new experiences.</div>
            <div>Sometimes, I might get carried away and forget about filming. You know...real life and stuff...😉</div>
          </div>
        </section>
        {/* flognity gaming */}
        <section id="YouTubeFlognityGaming" className="py-6">
          <div className="text-center my-6 mx-6 md:mx-16 xl:mx-40 flex flex-wrap justify-center items-center">
            <h1 className="text-5xl md:text-6xl xl:text-7xl md:whitespace-nowrap pr-4">flognity gaming</h1>
            <div className="mb-4 mt-10">
              <YouTubeSubscribeButton channelid="UC5UfTajJz4if5nQzf6zhUIA" />
            </div>
          </div>

          <YouTubePlayer
            key="flognityGaming_YouTube"
            id="flognityGaming_YouTube"
            videoId="yFMgJ_Mbuk0"
            containerWrapperClassName="float-left mr-8 mb-4"
            containerClassName="relative pt-0 h-0 overflow-hidden"
            className="absolute top-0 left-0 w-full h-full"
            opts={{ height: 315, width: 560 }}
          />

          <div>
            My{" "}
            <a href="https://www.youtube.com/flognitygaming">
              <strong>YouTube Channel</strong> <em>flognity gaming</em>
            </a>{" "}
            is all about video games, games in general and other interesting stuff. I love to sum up games, most
            importantly their story. Here are five good reasons, why you should watch my summaries, even though they
            contain 100% spoilers:
            <ol className="list-none py-4">
              <li>You want to know the story, but you don’t have time or the opportunity to play the game.</li>
              <li>You want to play the sequel and thus, you need a summary to know what happened before.</li>
              <li>You know the game and just want to refresh your memory.</li>
              <li>You want to know a thing or two about a specific game, which you don’t want to play.</li>
              <li>You want to enjoy awesome stories / videos.</li>
            </ol>
            However, you should definitely not watch my summaries, if you seriously want to play the game without being
            spoilered. For those viewers (and of course for the others as well), there is much more content for you on
            my channel, which might catch your attention. I also do playthroughs, building sessions for simulation
            games, game challenges with friends and other cool stuff. So turn in for my videos. You won’t regret it. 😊
          </div>

          <div className="clear-left">
            {/* Q&A */}
            <h2>{"Q&A"}</h2>
            <h3>What is your YouTube Channel about?</h3>
            <div>My channel is all about video games, games in general and other interesting stuff.</div>
            <div>I love to sum up games, most importantly their story.</div>
            <div>But there is much more content for you on my channel, which might catch your attention.</div>
            <div>
              I also do playthroughs, building sessions for simulation games, game challenges with friends and other
              cool stuff.
            </div>

            <h3>Why do you do YouTube videos?</h3>
            <div>
              It’s one of my hobbies. Furthermore, in my opinion, there are many masterpieces of video games which
              deserve much more attention and should be paid proper tribute.
            </div>
            <h3>When will you upload the next game summary?</h3>
            <div>
              Game summaries take a lot of effort and time. I want to deliver quality content. Thus, new game summaries
              will take their appropriate time. In the meantime you can enjoy my other videos and channels.
            </div>
          </div>
        </section>

        {/* <hr />
        <section id="YouTubeFlognityDe" className="py-6">
          <div className="text-center my-6 mx-6 md:mx-16 xl:mx-40 flex flex-wrap justify-center items-center">
            <h1 className="text-5xl md:text-6xl xl:text-7xl md:whitespace-nowrap pr-4">flognity DE</h1>
            <div className="mb-4 mt-10">
              <YouTubeSubscribeButton channelid="UCDECFfwKH3Z2q8kkG_A6rJQ" />
            </div>
          </div>

          <YouTubePlayer
            key="flognityDE_IchSsteigAus"
            id="flognityDE_IchSsteigAus"
            videoId="_Uj-y1EeGGI"
            containerWrapperClassName="float-left mr-8 mb-4"
            containerClassName="relative pt-0 h-0 overflow-hidden"
            className="absolute top-0 left-0 w-full h-full"
            opts={{ height: 315, width: 560 }}
          />

          <div>
            <h1>Willkommen in der Welt von flognity.</h1>
            <div>Ich bin ein Aussteiger aus dem Alltags-Job und Auswanderer aus Deutschland nach Kanada.</div>
            <div className="py-4">
              Ich habe meinen Alltagsbüro-Job hinter mir gelassen und werde Deutschland dauerhaft verlassen, um nach
              Kanada auszuwandern.
            </div>
            <div className="py-4">
              <a href="https://www.youtube.com/channel/UCDECFfwKH3Z2q8kkG_A6rJQ">
                <strong>Begleitet mich</strong>
              </a>{" "}
              auf meine Reise auf der Suche nach dem Glück.
            </div>
            <div className="py-4 font-bold">
              Tiefere Einblicke findest Du auf meinem{" "}
              <a href="https://www.youtube.com/channel/UCrKydXIhDJ5npnGMDQcWpZg">
                <strong>englischen Hauptkanal</strong>
              </a>
              . Zukünftig werden sich die Inhalte der beiden Kanäle deutlich unterscheiden.
            </div>
          </div>
          <div className="clear-left" />
        </section> */}
      </div>
    </div>
  );
}

export default YouTube;
