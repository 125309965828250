//Imports for components
import {
  IconCheckedBulletPoint,
  IconUncheckedBulletPoint,
  IconQuotationMark,
} from "../../components/icons/SvgIconsOwnDesign";

interface IachievedItem {
  item: string;
  url: string;
}

export default function Bucketlist() {
  const achievedArray: IachievedItem[] = [
    {
      item: "engage in environmental / wildlife conservation",
      url: "https://www.flognity.com/EnvironmentalPhilanthropy",
    },
    {
      item: "start my own YouTube channels",
      url: "https://www.flognity.com/YouTube",
    },
    {
      item: "visit the Chernobyl Exclusion zone and eat lunch at the Chernobyl Power Plant canteen",
      url: "https://youtu.be/Y02giC90znw",
    },
    {
      item: "snorkel the great barrier reef",
      url: "https://wilhelm-florian.blogspot.com/2011/06/cairns.html",
    },
    {
      item: "white water rafting",
      url: "https://wilhelm-florian.blogspot.com/2011/06/mission-beach.html",
    },
    {
      item: "do a multi-day sailing trip",
      url: "https://wilhelm-florian.blogspot.com/2011/06/whitsunday-islands.html",
    },
    {
      item: "go on a world trip (cross the equator twice on the trip and only go in one direction - either east or west and end up where I started)",
      url: "https://wilhelm-florian.blogspot.com/2011/03/gday-and-welcome-to-my-blog.html",
    },
    {
      item: "perform research with lasers",
      url: "https://floinkansas.blogspot.com/2014/08/hits-beam-time-and-trip-to-cosmosphere.html",
    },
    {
      item: "get tattoos",
      url: "https://www.instagram.com/p/CRv3XNQJ22i/?utm_source=ig_web_copy_link",
    },
    {
      item: "rehabilitate and help wildlife in need",
      url: "https://youtu.be/lrzDMw5Fca8",
    },
    {
      item: "become a homeowner",
      url: "https://youtu.be/08PfQ5x-TyA",
    },
  ];
  const unachievedArray = [
    "found a business",
    "become a landlord",
    "generate multiple streams of income",
    "gain financial independence",
    "engage in philanthropic activities to help others in need",
    "host a (group) fitness class",
    "be fluent in at least 4 languages",
    "learn about indigenous cultures and support indigenous communities",
    "live along with the Inuit and learn from their culture",
    "learn to play piano, guitar and drums",
    "compose my own music",
    "develop a video game",
    "develop a smartphone app (that I own myself and doesn’t belong to a company)",
    "explore marine live in various locations, including swimming with sharks and swimming with turtles",
    "engage in marine live conservation",
    "become a licensed solo skydiver",
    "go on a road trip in Russia with Russian companions",
    "go on the Trans-Siberian railway from Moscow to Beijing (or in reverse)",
    "go on a multi-country road trip in Africa",
    "visit countries in central Africa, learn about the respective country’s culture and try to help local communities",
    "get a motorbike license and go on an international motorcycle road trip",
    "experience an active volcano up close and see liquid lava",
    "finish an Ironman Triathlon",
    "fly in a wingsuit (base jumping)",
    "compete in a dog sled race",
    "swim in ice water and dive under a frozen lake or ocean",
    "swim in the arctic ocean",
    "write and publish my own book",
    "see the earth from space (space flight)",
    "walk on the moon",
    "win a motorized vehicle race",
    "visit all Disney and Universal theme parks",
  ];

  return (
    <div className="px-general my-4 pb-12 mx-auto xl:max-w-4xl 2xl:max-w-7xl">
      <h1 className="text-center text-4xl sm:text-6xl md:text-7xl xl:text-8xl pb-12">
        My Bucket List
      </h1>
      <div className="flex flex-col xl:flex-row-reverse xl:mb-12 xl:bg-blueGray-800 xl:bg-opacity-90 xl:rounded-lg">
        {/* image section */}
        <img
          className="h-72 w-full object-cover border-t-2 border-b-2 xl:border-2 xl:rounded-2xl xl:w-1/2"
          src="https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/TheList.jpg"
          alt="Florian Wilhelm - The List"
        />
        {/* Bucket List section */}
        <div className="py-12 xl:mx-4">
          <IconQuotationMark className="w-5 h-5" />
          <div className="text-justify p-4">
            The following bucket list includes activities and achievements I want to complete throughout my lifetime. Of
            course, the list is a living document. I will update and extend it from time to time. For each item that is
            checked, I will provide a link either as proof or to provide more information about my experience (i.e.
            blogs, videos and websites).
          </div>
          <IconQuotationMark className="w-5 h-5 float-right" />
        </div>
      </div>
      <div className="m-auto p-6 bg-blueGray-800 bg-opacity-70 border-2 rounded-3xl shadow-md">
        <div className="flex flex-col sm:items-start sm:text-left -mb-1 space-y-2.5">
          {achievedArray.map((achievedItem, index) => {
            return (
              <div key={`bucketlistItem_${index}`} className="flex flex-row items-center">
                <IconCheckedBulletPoint className="flex-none w-5 h-5 mr-2" />
                <a href={achievedItem.url}>
                  <div>{achievedItem.item}</div>
                </a>
              </div>
            );
          })}
          {unachievedArray.map((item, index) => {
            return (
              <div key={`bucketlistItem_${index}`} className="flex flex-row items-center">
                <IconUncheckedBulletPoint className="flex-none w-5 h-5 mr-2" />
                <div>{item}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
