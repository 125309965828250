//Imports for npm packages
// import { useEffect } from "react";
// import YTPlayer from "yt-player";
//Imports for components
//import useScript from "../hooks/useScript";

export default function YouTubePlayer(props: {
  videoId: string;
  id: string;
  className?: string;
  containerClassName?: string;
  containerWrapperClassName?: string;
  opts: { height?: number; width?: number };
}) {
  /**
   * Script for API doesn't work in index.html.
   * So we have to insert it after components rendered.
   */
  // const status = useScript("https://www.youtube.com/iframe_api");
  // useEffect(() => {
  //   if (status !== "ready") {
  //     const player = new YTPlayer("#" + props.id, props.opts);
  //     player.load(props.videoId);
  //   }
  // }, [props.id, props.opts, props.videoId, status]); // Only re-run effect if script src changes

  return (
    <div className={`${props.containerWrapperClassName} max-w-full left-0 right-0`} style={{width: props.opts.width}}>
      <div className={`${props.containerClassName}`} style={{paddingBottom: "56.24%"}}>
        <iframe
          key={props.videoId}
          className={props.className}
          src={`https://www.youtube-nocookie.com/embed/${props.videoId}`}
          title={props.id}
          height={props.opts.height}
          width={props.opts.width}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </div>
    </div>
  );

  // return <div id={props.id} className={props.className}></div>;
}
