//Imports for components
import useScript from '../hooks/useScript';


interface IYouTubeSubscribeButtonProps {
    channelid: string,
    theme?: string,
    layout?: string,
    count?: string
};

export default function YouTubeSubscribeButton({
    channelid,
    theme = "full",
    layout = "full",
    count = "default" }: IYouTubeSubscribeButtonProps) {


    /**
      * Script for API doesn't work in index.html.
      * So we have to insert it after components rendered.
      */
    const status = useScript("https://apis.google.com/js/platform.js");

    if (status !== "ready") {
        return <div>Loading...</div>;
    } else {
        return (
            <div
                className="g-ytsubscribe"
                data-channelid={channelid}
                data-layout={layout}
                data-count={count}
                data-theme={theme}
            />
        );
    }
}
