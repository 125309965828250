//Imports for npm packages
import { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
//Imports for components
import {
  IconEarth,
  IconCode,
  IconCompass,
  IconConservation,
  //IconFinance,
  IconScience,
  IconYouTube,
} from "../../components/icons/SvgIconsOwnDesign";
import { routesSiteMap } from "../routes";
import "./Home.css";

interface IiconArray {
  IconComponent: FunctionComponent<any>;
  text: string;
  linkTo: string;
  linkExact: boolean;
}

function Home() {
  const iconArray: IiconArray[] = [
    {
      IconComponent: IconCode,
      text: "Software Developer",
      linkTo: routesSiteMap.AboutMe.path,
      linkExact: routesSiteMap.AboutMe.exact,
    },
    {
      IconComponent: IconCompass,
      text: "Adventurer / Explorer",
      linkTo: routesSiteMap.Bucketlist.path,
      linkExact: routesSiteMap.Bucketlist.exact,
    },
    {
      IconComponent: IconConservation,
      text: "Environmental Philanthropist",
      linkTo: routesSiteMap.Philanthropy.path,
      linkExact: routesSiteMap.Philanthropy.exact,
    },
    // {
    //   IconComponent: IconFinance,
    //   text: "Financial Analyst",
    //   linkTo: routesSiteMap.AboutMe.path,
    //   linkExact: routesSiteMap.AboutMe.exact,
    // },
    {
      IconComponent: IconScience,
      text: "Physicist / Scientist",
      linkTo: routesSiteMap.AboutMe.path,
      linkExact: routesSiteMap.AboutMe.exact,
    },
    {
      IconComponent: IconYouTube,
      text: "YouTuber",
      linkTo: routesSiteMap.YouTube.path,
      linkExact: routesSiteMap.YouTube.exact,
    },
  ];

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [loopingLinksWidthArr, setLoopingLinksWidthArr] = useState(Array(iconArray.length).fill(0));

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    const updatedLoopingLinksWidthArr = Array(iconArray.length).fill(0);
    for (var i = 0; i < iconArray.length; i++) {
      const loopingLinks = document.getElementById(`loopitnIcon${i}`);
      if (loopingLinks) {
        updatedLoopingLinksWidthArr[i] = loopingLinks!.offsetWidth;
      }
    }
    setLoopingLinksWidthArr(updatedLoopingLinksWidthArr);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [iconArray.length]);

  const canvasSize = windowDimensions.width > 1400 ? 700 : (windowDimensions.width * 1) / 2;
  const animationTime = 30;

  return (
    <div className="px-general py-4 overflow-hidden">
      <section className="text-center pb-32">
        <h1 className="tracking-wider text-4xl md:text-5xl xl:text-6xl py-4">Welcome to the world of flognity</h1>
        <h2>
          I'm
          {iconArray.map((iconObject, index) => {
            return (
              <span
                key={`aOrAn_${index}`}
                className="inline-block whitespace-pre"
                style={{
                  position: "absolute",
                  opacity: index === 0 ? 1 : 0,
                  animation: `fade ${animationTime}s ease-in-out infinite`,
                  animationDelay: `${
                    index === 0 ? 0 : (animationTime / iconArray.length) * (iconArray.length - index)
                  }s`,
                }}>
                {/[aeiou]/i.test(iconObject.text[0]) ? " an..." : " a..."}
              </span>
            );
          })}
        </h2>
        <div className="pb-12 md:pb-20 xl:pb-24">
          <div
            className="relative h-5 m-auto"
            style={{
              width: `${canvasSize}px`,
            }}>
            {iconArray.map((iconObject, index) => {
              return (
                <NavLink
                  key={`iconText_${index}`}
                  id={`loopitnIcon${index}`}
                  to={iconObject.linkTo}
                  exact={iconObject.linkExact}
                  className="text-lg whitespace-nowrap"
                  style={{
                    position: "absolute",
                    opacity: index === 0 ? 1 : 0,
                    width: loopingLinksWidthArr[index] > 0 ? loopingLinksWidthArr[index] + "px" : "auto",
                    left: `calc(50% - ${loopingLinksWidthArr[index] / 2}px)`,
                    animation: `fade ${animationTime}s ease-in-out infinite, move-left-to-right ${animationTime}s ease-in-out infinite`,
                    animationDelay: `${
                      index === 0 ? 0 : (animationTime / iconArray.length) * (iconArray.length - index)
                    }s`,
                  }}>
                  {iconObject.text}
                </NavLink>
              );
            })}
          </div>
        </div>
        <div className="text-center">
          <div style={{ height: `${canvasSize}px` }}>
            <div
              className="relative m-auto border-current border-dashed border-2"
              style={{
                height: `${canvasSize}px`,
                width: `${canvasSize}px`,
                borderRadius: `${canvasSize / 2}px`,
              }}>
              {/* Place the earth in the middle of the screen */}

              <IconEarth
                style={{
                  position: "absolute",
                  width: `${canvasSize / 1.5}px`,
                  height: `${canvasSize / 1.5}px`,
                  left: `calc(50% - ${canvasSize / 3}px)`,
                  top: `calc(50% - ${canvasSize / 3}px)`,
                }}
              />
              <div
                className="w-full h-full"
                style={{
                  position: "absolute",
                  animation: `linear-rotation ${animationTime}s linear infinite`,
                }}>
                {iconArray.map((iconObject, index) => {
                  const iconWidth = canvasSize / 5;
                  //determine hight to maintain 16:9 aspect ratio
                  const iconHeight = (iconWidth / 16) * 9;

                  const iconStyle: CSSProperties = {
                    position: "absolute",
                    width: `${iconWidth}px`,
                    height: `${iconHeight}px`,
                    left: `calc(50% - ${iconWidth / 2}px)`,
                    top: `calc(50% - ${iconHeight / 2}px)`,
                  };

                  return (
                    <div
                      key={`iconKey_${index}`}
                      style={{
                        ...iconStyle,
                        transform: `rotate(${(360 / iconArray.length) * index}deg) translateX(${
                          -canvasSize / 2
                        }px) rotate(${(-360 / iconArray.length) * index}deg)`,
                      }}>
                      <div
                        className="group"
                        style={{
                          animation: `linear-rotation ${animationTime}s linear infinite`,
                          animationDirection: "reverse",
                        }}>
                        <div
                          className="absolute -top-8 hidden group-hover:block"
                          style={{
                            width: `${canvasSize}px`,
                            opacity: "1",
                            left: `calc(50% - ${canvasSize / 2}px)`,
                          }}>
                          {iconObject.text}
                        </div>
                        <NavLink to={iconObject.linkTo} exact={iconObject.linkExact}>
                          <iconObject.IconComponent fillIconPlate="#1E293B" />
                        </NavLink>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
