import { CSSProperties, useEffect, useState } from "react";
//Imports for components
import { IconQuotationMark } from "../../components/icons/SvgIconsOwnDesign";
import YouTubePlayer from "../../components/YouTubeApi/YouTubePlayer";
import "./Philanthropy.css";

interface Ipictures {
  src: string;
  alt: string;
  className?: CSSProperties;
}

function PictureCarousel(props: {
  className?: string;
  pictureArray: Ipictures[];
  pictureHeight: number;
  pictureWidth: number;
  pictureMargin: number;
}) {
  const className = props.className ?? "";
  const baseArrayLength = props.pictureArray.length * (props.pictureWidth + props.pictureMargin);

  const [arrayRepetitions, setArrayRepetitions] = useState(2);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [translatingDivWidth, setTranslatingDivWidth] = useState(baseArrayLength);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    //set the size of the window
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);

    //calculate if the multiplyer is high enough. If not, increment to the next highest even number
    const multiplyer = 2 + Math.floor((2 * windowDimensions.width) / (baseArrayLength * 2)) * 2;

    setArrayRepetitions(multiplyer);
    setTranslatingDivWidth(baseArrayLength * multiplyer);
    //clean up
    return () => window.removeEventListener("resize", handleResize);
  }, [arrayRepetitions, baseArrayLength, translatingDivWidth, windowDimensions.width]);

  return (
    <div className={`relative overflow-hidden ${className}`} style={{ left: "0%", height: `${props.pictureHeight}px` }}>
      <div
        className="absolute whitespace-nowrap"
        style={{
          left: "0%",
          width: translatingDivWidth + "px",
          animation: "linear-translate-pictures 50s linear infinite",
        }}>
        {/* repeat n times so it can loop in an animation */}
        {Array.from({ length: arrayRepetitions }, () => props.pictureArray)
          .flat()
          .map((picture, index) => {
            const imgClassName = picture.className ?? "";
            return (
              <img
                key={`ACE_img_${index}`}
                className={`inline-block object-cover border-2 rounded-2xl ${imgClassName}`}
                style={{
                  height: `${props.pictureHeight}px`,
                  width: `${props.pictureWidth}px`,
                  marginRight: `${props.pictureMargin}px`,
                }}
                src={picture.src}
                alt={picture.alt}
              />
            );
          })}
      </div>
      <div
        className="absolute w-6 xl:w-16 bg-gradient-to-r from-blueGray-800 to-transparent"
        style={{ left: "0%", height: `${props.pictureHeight}px` }}
      />
      <div
        className="absolute w-6 xl:w-16 bg-gradient-to-r from-transparent to-blueGray-900"
        style={{ right: "0%", height: `${props.pictureHeight}px` }}
      />
    </div>
  );
}

export default function Philanthropy() {
  const pictureArray: Ipictures[] = [
    {
      src: "https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_Zion_National_Park_Angels_LandingJPG.jpg",
      alt: "ACE - A group of volunteers on top of Angels Landing at Zion National Park",
    },
    {
      src: "https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_Florian_planting_a_cactusJPG.jpg",
      alt: "ACE - Florian planting a cactus",
    },
    {
      src: "https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_Florian_planting_seeds.JPG",
      alt: "ACE - Florian planting a seeds",
    },
    {
      src: "https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_Florian_Wilhelm_building_trailJPG.jpg",
      alt: "ACE - Florian building a trail",
    },
    {
      src: "https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_group_of_volunteers_at_trail_work_projectJPG.jpg",
      alt: "ACE - group of volunteers at a trail work project",
    },
    {
      src: "https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_Florian_Wilhelm_fencing_projectJPG.jpg",
      alt: "ACE - Florian at a fencing project",
    },
    {
      src: "https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_a_deer_at_the_campsiteJPG.jpg",
      alt: "ACE - a deer at the campsite",
    },
    {
      src: "https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_campsite_at_Zion_National_ParkJPG.jpg",
      alt: "ACE - campsite at Zion National Park",
    },
    {
      src: "https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_Florian_Wilhelm_on_mountainJPG.jpg",
      alt: "ACE - Florian Wilhelm on a Mountain",
    },
  ];

  const pictureWidth = 288; //px
  const pictureHeight = (pictureWidth / 16) * 9; //maintain aspect ration 16:9
  const pictureMargin = 32; //px

  return (
    <div className="mx-auto py-4 ">
      <h1 className="px-4 md:px-8 xl:px-12 text-center text-3xl sm:text-5xl md:text-6xl xl:text-7xl pb-12">
        Environmental Philanthropy
      </h1>
      <PictureCarousel
        className="my-auto"
        pictureArray={pictureArray}
        pictureHeight={pictureHeight}
        pictureWidth={pictureWidth}
        pictureMargin={pictureMargin}
      />

      <div className="px-2 md:px-8 xl:px-12 pt-12 lg:grid lg:gap-4 lg:grid-cols-2 xl:grid-cols-3">
        <section className="px-general pb-8 mx-auto w-full xl:max-w-4xl 2xl:max-w-7xl">
          <h2 className="heading">My approach to Philanthropy</h2>

          <div className="text-base xl:text-lg">
            <IconQuotationMark className="w-5 h-5 mb-4" />
            <div>
              You can't change the world if you don't want to get your hands dirty. I've always considered myself a
              person of action and not a guy of empty words. My problem with monetary donations to some organizations is
              that you can't eat money or water a tree with money. But you can spend your money on food and water
              supply. That's why I want to help out in person to make the world a better place. For me, volunteering for
              a bigger cause is the best option to spend your time wisely. On this page, I want to give a shout-out to a
              selection of organizations that I believe in and I have volunteered with. I will update this page once in
              a while to include more projects and organizations I'm supporting. So feel free to come back to this page
              in the future.
            </div>
            <IconQuotationMark className="w-5 h-5 float-right mt-4" />
          </div>
        </section>
        <section className="px-general pb-8 mx-auto w-full xl:max-w-4xl 2xl:max-w-7xl">
          <h2 className="heading">American Conservation Experience (ACE) - USA</h2>
          <img
            className="w-full object-cover border-b-2 border-t-2 rounded-xl mb-4"
            src="https://storage.googleapis.com/scenic-style-300622.appspot.com/public/img/ACE_Zion_National_Park_Angels_LandingJPG.jpg"
            alt="ACE - A group of volunteers on top of Angels Landing at Zion National Park"
          />

          <div className="text-base xl:text-lg">
            The <a href="https://www.usaconservation.org/">American Conservation Experience (ACE)</a> is a non-profit
            organization that aims to help and restore the environment of US national parks, US national monuments and
            other treasures of nature in the United States. With an international team of volunteers, this organization
            is doing its best to preserve our precious nature. I've been volunteering with ACE in 2011 for 3 months and
            might come back for another term soon.
          </div>
        </section>

        <section className="px-general pb-8 mx-auto w-full xl:max-w-4xl 2xl:max-w-7xl">
          <h2 className="heading">Aspen Valley Wildlife Sanctuary - Ontario, Canada</h2>
          <YouTubePlayer
            key="flognit_AspenValleyVideo"
            id="flognit_AspenValleyVideo"
            videoId="lrzDMw5Fca8"
            containerWrapperClassName="float-left mr-8 mb-4"
            containerClassName="relative pt-0 h-0 overflow-hidden"
            className="absolute top-0 left-0 w-full h-full"
            opts={{ height: 315, width: 560 }}
          />
          <div className="text-base xl:text-lg">
            <a href="https://www.aspenvalley.ca/">Aspen Valley Wildlife Sanctuary</a> is a non-profit, registered
            charity located in Rosseau, Ontario. They are authorized by the Ministry of Natural Resources and Forestry
            to rescue, rehabilitate, and release native Ontario wildlife. They are the only rehabilitation facility in
            Ontario that also has a Sanctuary for non-releasable wildlife. They receive no government funding and solely
            rely on the generosity of the public. So, please consider volunteering or donating at{" "}
            <a href="https://www.aspenvalley.ca/">https://www.aspenvalley.ca/</a>
          </div>
          <div className="text-base xl:text-lg">
            I have personally volunteered with Aspen Valley and helped out with their wildlife in care.{" "}
            <span className="font-bold">
              I was a full-time animal care volunteer from November 2021 until January 2022.
            </span>
            I greatly enjoyed my time there and I'm happy to have made this experience of helping to raise beavers,
            otters and other native Ontario wildlife. I also personally donated to Aspen Valley and I can highly
            recommend this charity since I know that the donations are put to good use. The money will help to feed the
            animals. But the greatest gift is your time. So please consider volunteering and helping wildlife directly
            hands-on.
          </div>

        </section>
      </div>
    </div>
  );
}
