//Imports for npm packages
import { useState } from "react";
import { NavLink } from "react-router-dom";
//Imports for components
import "./Navbar.css";
import { routesSiteMap } from "../../domains/routes";
import { IconMenu, IconX } from "../icons/Heroicons";

function Navbar(props: { logoSource: string; logoAlt?: string; logoSmSource?: string; logoSmAlt?: string }) {
  //Define default values
  const logo = props.logoSource;
  const logoAlt = props.logoAlt ?? "Navbar Logo";
  const logoSmall = props.logoSmSource ?? props.logoSource;
  const logoSmallAlt = props.logoSmAlt ?? "Navbar Logo";

  const [isOpen, setOpen] = useState(false);

  return (
    <nav className="bg-blueGray-800 sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3 border-solid relative shadow-lg">
      {/* Navbar div */}
      <div className="flex items-center justify-between px-4 py-3 sm:p-0 lg:px-8">
        {/* div for everything on the left */}
        <div>
          {/* responsive logo */}

          <NavLink to="/" exact={true} className="logo">
            <img className="block lg:hidden logo" src={logoSmall} alt={logoSmallAlt} />
            <img className="hidden lg:block logo" src={logo} alt={logoAlt} />
          </NavLink>
        </div>

        {/* div for everything on the right */}
        <div>
          {/* mobile hamburger navigation button */}
          <div className="sm:hidden">
            <button
              onClick={() => {
                setOpen(!isOpen);
              }}
              type="button"
              className="text-warmGray-300 hover:text-warmGray-50 focus:text-warmGray-50 focus:outline-none">
              {/* burger icon when menu is closed */}
              <IconMenu className={`h-10 w-10 fill-current ${isOpen && "hidden"}`} />
              {/* x icon when menu is open */}
              <IconX className={`h-10 w-10 fill-current ${!isOpen && "hidden"}`} />
            </button>
          </div>
        </div>
      </div>

      {/* div for the mobile menu buttons */}
      <div className={`${isOpen ? "block" : "hidden"} px-2 pt-2 pb-4 sm:flex`}>
        {Object.entries(routesSiteMap).map(([routeKey, route]) => {
          return (
            <NavLink
              key={`NavbarSiteMap_${routeKey}`}
              to={route.path}
              exact={route.exact}
              className="block links hover:bg-blueGray-700"
              activeClassName="block links bg-blueGray-700"
              onClick={() => {
                setOpen(false);
              }}>
              {route.text}
            </NavLink>
          );
        })}
      </div>
    </nav>
  );
}

export default Navbar;
