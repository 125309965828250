//Imports for npm packages
import { FunctionComponent } from "react";

export const SVGViewBox24: FunctionComponent<{
  className?: string;
  fill?: string;
  stroke?: string;
}> = (props) => {
  const fill = props.fill ?? "none";
  const stroke = props.stroke ?? "currentColor";
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      viewBox="0 0 24 24"
      stroke={stroke}
    >
      {props.children}
    </svg>
  );
};
