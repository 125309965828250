//Imports for npm packages
import React, { useState } from "react";
//Imports for components
import { IconCalendar, IconChevronDown, IconChevronUp } from "../../components/icons/Heroicons";

function EducationTile(props: { school: string; major: string; dates: string; degree: string }) {
  return (
    <div className="pr-6 pl-6 pb-2 pt-2 m-2 mb-6 rounded-3xl border-t-2 border-b-2 relative shadow-2x bg-blueGray-800 bg-opacity-30 break-words">
      {/* school name */}
      <h2 className="tracking-wider mb-1 font-medium">{props.school}</h2>
      {/* major */}
      <h3 className="-mt-4 font-medium">
        {props.degree}, {props.major}
      </h3>
      {/* Dates */}
      <div className="flex flex-row">
        {/* Heroicon calendar */}
        <IconCalendar className="w-5 pt-1" />
        <div className="pl-2">{props.dates}</div>
      </div>
    </div>
  );
}

export default function Education() {
  const [isEducationShown, setEducationShown] = useState(true);
  return (
    <section className="bg-blueGray-900 rounded-3xl bg-opacity-30">
      <div
        className="pl-6 mt-12 mb-2 align-top bg-blueGray-900 rounded-3xl bg-opacity-60 flex flex-row items-center justify-start cursor-pointer text-warmGray-300 hover:text-warmGray-50 focus:text-warmGray-50 focus:outline-none"
        onClick={() => {
          setEducationShown(!isEducationShown);
        }}>
        {/* div with the icon */}
        <div className="p-2">
          <IconChevronUp className={`h-6 w-6 fill-current border-2 rounded-md ${!isEducationShown && "hidden"}`} />
          <IconChevronDown className={`h-6 w-6 fill-current border-2 rounded-md ${isEducationShown && "hidden"}`} />
        </div>
        <h2 className="text-center text-2xl tracking-widest">Education</h2>
      </div>
      <div
        className={`${
          isEducationShown ? "transition duration-100 ease-in-out transform scale-y-100" : "transform scale-y-0 h-0"
        }`}>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 ">
          <EducationTile
            dates="2014 - 2017"
            school="Goethe-University Frankfurt, Germany"
            major="Computational Physics"
            degree="Master of Science (M.Sc.)"
          />
          <EducationTile
            dates="2013 - 2016"
            school="Goethe-University Frankfurt, Germany"
            major="Economics and Business Administration"
            degree="Bachelor of Science (B.Sc.)"
          />
          <EducationTile
            dates="2011 - 2014"
            school="Goethe-University Frankfurt, Germany"
            major="Physics"
            degree="Bachelor of Science (B.Sc.)"
          />
        </div>
      </div>
    </section>
  );
}
