//Imports for components
import Navbar from '../navbar/Navbar';

function Header() {
    return (
        <header>
            <Navbar logoSource="/img/flognityLogo.png" logoAlt="flognity Logo" />
        </header>
    );
}

export default Header;
