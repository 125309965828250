//Imports for npm packages
import React, { useEffect } from "react";

declare global {
  interface Window {
    GoogleReCaptcha_onload?: Function;
  }
}

export enum EstatusCode {
  Loading = "loading",
  Ready = "ready",
  Idle = "idle",
}

//create the wrapper
export default function ReCAPTCHAinitialize(
  satusSetter: React.Dispatch<React.SetStateAction<EstatusCode>>
) {
  const recaptchaAPIscript =
    "https://www.google.com/recaptcha/api.js?render=explicit&onload=GoogleReCaptcha_onload&hl=en";

  useEffect(() => {
    //invoked when the Google reCAPTCHA JS API is loaded to set the loaded state
    function onLoadHandler() {
      // remove the onload handler since it got used
      delete window.GoogleReCaptcha_onload;
      // propagate the loaded event/state
      satusSetter(EstatusCode.Ready);
    }

    window.GoogleReCaptcha_onload = onLoadHandler;
    //only create the script element if it doesn't exist yet
    let script: HTMLScriptElement;
    const scriptfetch: HTMLScriptElement | null = document.body.querySelector(
      `script[src="${recaptchaAPIscript}"]`
    );
    if (!scriptfetch) {
      window.GoogleReCaptcha_onload = onLoadHandler;
      script = document.createElement("script");
      script.src = recaptchaAPIscript;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    } else if (window.GoogleReCaptcha_onload) {
      window.GoogleReCaptcha_onload = onLoadHandler;
    } else {
      satusSetter(EstatusCode.Ready);
    }

    // Specify how to clean up after this effect:
    return () => {
      if (window.GoogleReCaptcha_onload) {
        window.GoogleReCaptcha_onload = (): void => {
          // remove the onload handler since it got used
          delete window.GoogleReCaptcha_onload;
        };
      }
      //Remove the script
      let scriptDelete = document.body.querySelector(
        `script[src="${recaptchaAPIscript}"]`
      );
      if (scriptDelete) {
        document.body.removeChild(scriptDelete);
        satusSetter(EstatusCode.Idle);
      }
      //Remove the second script, google adds automatically
      scriptDelete = document.body.querySelector(
        `script[src*="gstatic.com/recaptcha"]`
      );
      if (scriptDelete) {
        document.body.removeChild(scriptDelete);
        satusSetter(EstatusCode.Idle);
      }
    };
  }, [recaptchaAPIscript, satusSetter]); // Only re-run effect if status changes
}
