//Imports for npm packages
import React, { Fragment } from "react";

const contactDetails = {
    name: "Florian Wilhelm",
    street: "1 Ave NE",
    unit: "704",
    streetNr: "1451",
    addrAdditional: "",
    state: "BC",
    zipCode: "V1E 1N9",
    City: "Salmon Arm",
    country: "Canada",
    phoneNr: "+1 (250) 253 7995",
    email: "flognity@gmail.com",
    website: {
        url: "http://www.flognity.com/",
        name: "flognity.com"
    }
}

const currentAddress = {
    addrLine1: contactDetails.name,
    addrLine2: `${contactDetails.unit}${contactDetails.unit && "-"}${contactDetails.streetNr} ${contactDetails.street}`,
    addrLine3: contactDetails.addrAdditional,
    addrLine4: `${contactDetails.City}, ${contactDetails.state}, ${contactDetails.zipCode}`,
    addrLine5: contactDetails.country
}

const CurrentAddressBr = () => {
    return <Fragment>
    {currentAddress.addrLine1 && currentAddress.addrLine1 }
    {currentAddress.addrLine2 && <Fragment> <br /> {currentAddress.addrLine2} </Fragment> }
    {currentAddress.addrLine3 && <Fragment> <br /> {currentAddress.addrLine3} </Fragment> }
    {currentAddress.addrLine4 && <Fragment> <br /> {currentAddress.addrLine4} </Fragment> }
    {currentAddress.addrLine5 && <Fragment> <br /> {currentAddress.addrLine5} </Fragment> }
    </Fragment>
}

export {currentAddress, contactDetails, CurrentAddressBr};