//Imports for npm packages
import { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
//Imports for components
import { routesSiteMap } from "../routes";
import "./AboutMe.css";
import SoftwareDeveloper from "./SoftwareDeveloper";
import Education from "./Education";

export default function AboutMe() {
  const ContactRoute = routesSiteMap.Contact;
  const [loopingDivHeight, setLoopingDivHeight] = useState(60);

  useEffect(() => {
    //set state for the height of the rotating Div
    const loopingDiv = document.getElementById("loopingDiv");
    if (loopingDiv) {
      setLoopingDivHeight(loopingDiv!.offsetHeight);
    }

    //create animation for who I am
    const duration = 3000; //in ms
    const whoIAmDiv = document.getElementById("whoIAmDiv");
    const textWhoIAm = ["a Physicist", "a YouTuber", "a Content Creator", "an Application Developer"];
    const animKeyFrames: Keyframe[] | PropertyIndexedKeyframes = [
      { opacity: 0, easing: "ease-out" },
      { opacity: 1, easing: "ease-in" },
      { opacity: 0, easing: "ease-out" },
    ];

    var counter = 0;
    if (whoIAmDiv) {
      function LoopText() {
        whoIAmDiv!.innerHTML = textWhoIAm[counter];

        whoIAmDiv!.animate(animKeyFrames, { duration: duration });

        counter++;
        if (counter >= textWhoIAm.length) {
          counter = 0;
        }
      }
      whoIAmDiv!.animate(animKeyFrames, { duration: duration });
      setInterval(LoopText, duration);
    }
  }, []);

  return (
    <Fragment>
      <section className="px-general mx-auto pb-6 w-full xl:max-w-4xl 2xl:max-w-7xl">
        <div className="grid grid-cols-12 gap-4 items-center place-items-center">
          <img
            className="rounded-full col-span-6 sm:col-span-4 xl:col-span-2 xl:col-start-1"
            src="/img/Florian_Wilhelm.jpg"
            alt="Portrait of Florian Wilhelm"
          />
          <img
            className="rounded-full hidden sm:block sm:col-span-4 xl:hidden"
            src="/img/FlorianWilhelmBack.jpg"
            alt="Florian Wilhelm showing thumps up"
          />
          <img
            className="rounded-full col-span-6 sm:col-span-4 xl:col-span-2 xl:col-start-11"
            src="/img/LaserLab.jpg"
            alt="Florian Wilhelm showing thumps up"
          />
          <div className="col-start-1 row-start-2 col-span-12 xl:row-start-1 xl:col-start-3  xl:col-span-8">
            <h1 className="tracking-wider text-4xl md:text-5xl xl:text-6xl text-center">Florian Wilhelm</h1>
            <div className="font-medium text-lg md:text-xl xl:text-1xl pb-4 text-center">
              <div className="inline-block whitespace-pre">I'm </div>
              <div className="inline-block" id="whoIAmDiv">
                an Application Developer
              </div>
              <div className="inline-block">.</div>
            </div>
            <div className="pt-4 text-justify">
              I’m a well-diversified and experienced <strong>full-stack Application Developer</strong> and Physicist
              with work exposure in the Financial Sector as well as knowledge in <strong>Computational Sciences</strong>
              . On top of that, I create <strong>YouTube</strong> videos and creative content.
            </div>
          </div>
        </div>
        <div className="pt-4 text-justify">I can develop your next:</div>

        <div className="relative overflow-hidden" style={{ height: loopingDivHeight, left: "0%" }}>
          <div
            id="loopingDiv"
            className="absolute whitespace-nowrap"
            style={{
              left: "0%",
              animation: "linear-translate-ICanDevelop 50s linear infinite",
            }}>
            {/* repeat 2 times so it can loop in an animation */}
            {[0, 1].map((index) => {
              return (
                <Fragment key={`ICanDevelop_${index}`}>
                  <div className="item-ICanDevelop">responsive and reactive web application</div>
                  <div className="item-ICanDevelop">multi-platform app for Android, iOS, Windows, Mac and Linux</div>
                  <div className="item-ICanDevelop">automation or scheduling scripts</div>
                  <div className="item-ICanDevelop">near-time or batch reporting application</div>
                  <div className="item-ICanDevelop">data visualization and analyzing tool</div>
                  <div className="item-ICanDevelop">API or interface for existing software</div>
                </Fragment>
              );
            })}
          </div>
          <div
            className="absolute w-16 bg-gradient-to-r from-blueGray-800 to-transparent"
            style={{ height: loopingDivHeight, left: "0%" }}
          />
          <div
            className="absolute w-16 bg-gradient-to-r from-transparent to-blueGray-900"
            style={{ height: loopingDivHeight, right: "0%" }}
          />
        </div>
        <div className="pt-4 text-justify">

            If you want to work with me, just head to my{" "}
            <NavLink to={ContactRoute.path} exact={ContactRoute.exact}>
              contact page
            </NavLink>{" "}
            and leave a message. Whether I can help you as a developer, a video maker and YouTuber or as a scientist,
            I’m sure we will find the best solution.

        </div>
        <SoftwareDeveloper />

        <Education />
      </section>
    </Fragment>
  );
}
