//Imports for npm packages
import { NavLink } from "react-router-dom";
//Imports for components
import {
  linksSocialMedia,
  routesSiteMap,
  routesLegalLinks,
} from "../../domains/routes";
import "./Footer.css";

function Footer() {
  const dateToday = new Date();

  return (
    <footer className="px-general bg-cyan-800 text-warmGray-200 relative bottom-">
      {/* Site Map Links */}
      <div className="flex flex-row justify-between">
      <div className="flex flex-wrap justify-start my-4">
        {Object.entries(routesSiteMap).map(([routeKey, route]) => {
          return (
            <NavLink
              key={`FooterSiteMap_${routeKey}`}
              to={route.path}
              exact={route.exact}
              className="footer-links pr-8 flex-1 whitespace-nowrap"
            >
              {route.text}
            </NavLink>
          );
        })}
      </div>
      <div className="flex flex-wrap justify-end my-4 content-start border-l-2">
        {Object.entries(routesLegalLinks).map(([routeKey, route], index) => {
          return (
            <div key={`FooterLegalLinks_${routeKey}_${index}`}>
              {/* {index > 0  && <span className="pr-4">|</span> } */}
              <NavLink
                to={route.path}
                exact={route.exact}
                className="footer-links pl-8 flex-1 whitespace-nowrap"
              >
                {route.text}
              </NavLink>
            </div>
          );
        })}
      </div>
      </div>

      {/* Social Media Links */}
      <div className="flex flex-wrap justify-center items-center">
        {Object.entries(linksSocialMedia).map(([linkKey, link]) => {
          return (
            <a key={linkKey} className="pb-4 pr-6" href={link.href}>
              <img
                className="object-contain w-12 h-12 sm:w-8 sm:h-8"
                src={link.icon}
                alt={link.text}
              />
            </a>
          );
        })}
      </div>

      <hr />

      


      <div className="sm:text-center my-2">
          Coypright © {dateToday.getFullYear()} flognity - Florian Wilhelm | Designed and programmed by Florian Wilhelm
        </div>
    </footer>
  );
}

export default Footer;
